import { Theme } from '@emotion/react';

const calcRem = (size: number) => `${size / 16}rem`;

/* src/assets/styles/emotion.d.ts 에 타입 정의 필요함 */
const gray = {
  gray100: '#E1E1E1',
  gray200: '#CFCFCF',
  gray300: '#B1B1B1',
  gray400: '#9E9E9E',
  gray500: '#7E7E7E',
  gray600: '#626262',
  gray700: '#515151',
  gray800: '#3B3B3B',
  gray900: '#222222',
};

const darkGray = {
  default: '#121212',
};

const white = {
  default: '#FFFFFF',
};

const menu = {
  backA: '#DEE6FF',
  backB: '#F0F4FF',
  backC: '#FCFDFF',
  backD: '#F5F5F5',
  backE: '#F6F9FF',
};

const color = {
  default: '#000000',
  gray,
  darkGray,
  white,
  menu,
  primary: {
    default: '#3462FF',
    disabled: '#96ADFC',
    hover: '#5C81FF',
    active: '#2A4ECC',
    focus: '#2A4ECC',
  },
  secondary: {
    default: '#A1A1A1',
  },
  tertiary: {
    default: '#8E8E8E',
  },
  error: {
    text: '#E84562',
    default: '#E83232',
    disabled: '#EC5A5A',
    hover: '#EC5A5A',
    active: '#D02D2D',
    focus: '#D02D2D',
  },
  line: gray.gray300,
  line2: gray.gray200,
  line3: '#B0B8C1',
};

const formColor = {
  default: '#CFCFCF',
  focus: '#3462FF',
  checked: '#3462FF',
  error: '#E83232',
  icon: '#B0B8C1',
};
const formRadius = {
  input: '6px',
  checkbox: '4px',
};

const boxShadow = {
  normal: '0 3px 8px 0 rgb(0 0 0 / 10%)',
  purple: '0 3px 8px 0 #d6c9ff',
  blue: '0 3px 8px 0 #b3e2e6',
};

const padding = {
  small: calcRem(8),
  base: calcRem(10),
  lg: calcRem(12),
  xl: calcRem(14),
  xxl: calcRem(16),
  xxxl: calcRem(18),
};

const theme: Theme = {
  color,
  boxShadow,
  padding,
  formColor,
  formRadius,
};

export default theme;
