import React, { useCallback } from 'react';
import { useNotificationsSettingQuery } from '@/pages/Notifications/hooks/useNotificationsSettingQuery';
import styled from '@emotion/styled';
import theme from '@/assets/styles/theme';
import Switch from '@/components/switch/Switch';
import useNotificationsSetting from '@/pages/Notifications/hooks/useNotificationsSettingMutation';

function NotificationSettings() {
  const { data } = useNotificationsSettingQuery();
  const { mutate } = useNotificationsSetting();

  const resolvedSettingKorean = (name: string) => {
    switch (name) {
      case 'system':
        return '시스템 알림';
      case 'notice':
        return '공지 알림';
      case 'marketing':
        return '이벤트 및 혜택 알림';
      default:
        return '';
    }
  };

  const onNotificationSettingChange = useCallback(
    (value: string) => async (checked: boolean) => {
      if (data) {
        const updateValues = {
          ...data.settings,
          [value]: checked,
        };
        await mutate(updateValues);
      }
    },
    [mutate, data],
  );

  if (!data) {
    return <></>;
  }

  const { settings } = data;

  return (
    <NotificationSetting>
      {Object.entries(settings).map((setting) => {
        return (
          <li key={setting[0]}>
            {resolvedSettingKorean(setting[0])}
            <Switch checked={setting[1]} onChange={onNotificationSettingChange(setting[0])} />
          </li>
        );
      })}
    </NotificationSetting>
  );
}

export default NotificationSettings;

const NotificationSetting = styled.ul`
  border-top: 1px solid ${theme.color.gray.gray100};
  padding: 22px 20px;

  li {
    display: flex;
    justify-content: space-between;
    height: 52px;
  }
`;
