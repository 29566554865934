import React, { useEffect } from 'react';
import { getSerializedData, setSerializedData, setSessionSerializedData } from '@/lib/util/storage';
import { useOrganizationsQuery } from '@/pages/Organizations/hooks/useOrganizationsQuery';
import { useRecoilState } from 'recoil';
import { useLocation, useNavigate } from 'react-router-dom';
import { authState } from '@/atoms/authState';
import Loading from '@/components/loading/Loading';

function Organizations() {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as any;

  const [authInfo] = useRecoilState(authState);
  const { isAuthStateLoaded, isLogged, email } = authInfo;

  const { data: organizations, isLoading } = useOrganizationsQuery();

  let organizationLocal = getSerializedData('organization-persist');

  useEffect(() => {
    if (isAuthStateLoaded && !isLogged) {
      navigate('/sign-in', { replace: true });
    }
  }, [isAuthStateLoaded, isLogged, navigate]);

  /**
   * 자동으로 워크스페이스 정보 저장
   */
  useEffect(() => {
    function navigateReplace() {
      if (state) {
        navigate('/', {
          replace: true,
          state,
        });
      } else {
        navigate('/', { replace: true });
      }
    }

    if (isLogged && !isLoading) {
      if (
        organizationLocal &&
        organizations.findIndex((item) => item.id === organizationLocal.id) === -1
      ) {
        organizationLocal = {
          email: email,
          id: organizations[0].id,
          persist: true,
        };
        setSerializedData('organization-persist', organizationLocal);
        setSessionSerializedData('organization', organizationLocal);

        navigateReplace();

        return;
      } else if (
        organizationLocal &&
        organizations.findIndex((item) => item.id === organizationLocal.id) !== -1
      ) {
        navigateReplace();

        setSessionSerializedData('organization', organizationLocal);

        return;
      }

      organizationLocal = {
        email: email,
        id: organizations[0].id,
        persist: true,
      };
      setSerializedData('organization-persist', organizationLocal);

      setSessionSerializedData('organization', organizationLocal);

      navigateReplace();
    }
  }, [organizations, navigate, isLoading, email, isLogged, organizationLocal]);

  return <Loading />;
}

export default Organizations;
