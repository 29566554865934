import apiClient from '@/lib/api/apiClient';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { messageInfo } from '@/components/popup/message';

export default function useUserInfoMutation() {
  const queryClient = useQueryClient();
  const patchUserInfo = async (params: {
    displayName: string;
    mobile: string;
    password?: string;
  }) => {
    const { data } = await apiClient.patch(`/user`, params);
    return data;
  };

  return useMutation(patchUserInfo, {
    onError: async () => {
      await queryClient.invalidateQueries(['user']);
      await messageInfo({
        content: '회원정보 변경에 실패했습니다.',
        noIcon: true,
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(['user']);
      await messageInfo({
        content: '성공적으로 변경되었습니다.',
        noIcon: true,
      });
    },
  });
}
