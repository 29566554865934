import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { TButtonProps } from './Button';
import theme from '@/assets/styles/theme';
import { Button } from 'antd';
import { media } from '@/assets/styles/GlobalStyles';

export const StyledButton = styled(Button)((props: { design: TButtonProps['design'] }) => {
  const { design } = props;

  let styledButton = ``;

  switch (design) {
    case 'tiny':
      styledButton = styledTiny;
      break;
    case 'primary':
      styledButton = styledPrimary;
      break;
    case 'secondary':
      styledButton = styledSecondary;
      break;
    case 'tertiary':
      styledButton = styledTertiary;
      break;
    case 'quaternary':
      styledButton = styledQuaternary;
      break;
    case 'quinary':
      styledButton = styledQuinary;
      break;

    case 'outline':
      styledButton = styledOutline;
      break;
    case 'icon':
      styledButton = styledIcon;
      break;
    case 'iconWithText':
      styledButton = styledIconWithText;
      break;
    case 'modal1':
      styledButton = styledModal1;
      break;
    case 'modal2':
      styledButton = styledModal2;
      break;
    case 'modalSecondary':
      styledButton = styledModalSecondary;
      break;
    case 'form1':
      styledButton = styledForm1;
      break;
  }

  return css`
    border-radius: 0.6rem;
    border: none !important;
    box-shadow: none !important;
    transition: none !important;

    ${styledButton}
    // antd - loading시 노출되지 않도록 추가
    &:before {
      display: none;
    }

    // antd - 버튼 클릭시 노출되지 않도록 추가
    &:after {
      display: none;
    }

    .ant-btn-loading-icon {
      position: absolute !important;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      .anticon {
        padding: 0;
      }
    }

    &.ant-btn-sm {
      font-size: 12px;
      min-height: 36px;
      padding: 0 1.4rem;
    }
  `;
});

export const styledQuinary = `
  min-height: 4.8rem;
  padding: 0 1.4rem;
  font-size: 1.4rem;
  line-height: 128.9%;
  color: ${theme.color.gray.gray700};
  background-color: ${theme.color.white.default};
  border: 1px solid ${theme.color.gray.gray700} !important;
  
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  width: 100%;
  
  svg {
    margin-right: 1rem;
  }
`;

export const styledTiny = `
  height: 20px;
  margin-top: 1rem;
  min-height: 32px;
  width: 102px;
  font-size: 12px;
  font-weight: normal;
  padding: 0;
  display: flex;
  justify-content: center;
  border: 1px solid #9E9E9E !important;
  color: #626262 !important;
  
  ${media.mobile} {
  margin-top: 1.2rem;
`;

export const styledPrimary = `
  min-height: 4.8rem;
  padding: 0 1.4rem;
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 128.9%;
  color: #fff !important;
  background-color: ${theme.color.primary.default};
  border: none !important;
  
  &:focus {
    color: ${theme.color.white} !important;
    background-color: ${theme.color.primary.default} !important;
  }
  
  &:hover {
    background-color: ${theme.color.primary.hover} !important;
  }
  
  &:active {
    background-color: ${theme.color.primary.active} !!important;
  }
  
  &:disabled {
    color: rgba(255, 255, 255, .5) !important;
    background-color:${theme.color.primary.disabled} !important;
  }

`;

export const styledSecondary = `
  min-height: 4rem;
  padding: 0 .8rem;
  border: 1px solid ${theme.color.line} !important;
  font-weight: 700;
  font-size: 14px;
  line-height: 128.9%;
  color: #474E60 !important;
  background: #FFFFFF;
  
  &:focus {
    color: #191919 !important;
    background: #FFFFFF;
  }
  
  &:hover {
    background-color: #F3F3F3;
  }
  
  &:active {
    background-color: #E9E9E9;
  }
`;

export const styledTertiary = `
  padding: 0 1rem;
  border: none !important;
  font-weight: 400;
  font-size: 14px;
  line-height: 128.9%;
  color: #000;
  background-color: transparent;
  
  &:focus {
    color: #000;
    background-color: transparent;
  }
  
  &:hover {
    color: inherit;
    background-color: transparent;
  }
  
  &:active {
    color: inherit;
    background-color: transparent;
  }
`;

export const styledTertiaryOther = css`
  color: ${theme.color.secondary.default};

  &:focus {
    color: ${theme.color.secondary.default};
  }

  &:active,
  &:hover {
    color: ${theme.color.secondary.default};
  }
`;

export const styledQuaternary = `
  height: 4.8rem;
  padding: 0 1rem;
  border: 1px solid ${theme.color.primary.default} !important;
  font-weight: 400;
  font-size: 14px;
  line-height: 128.9%;
  color: ${theme.color.primary.default};
  background-color: transparent;
  
  &:focus,
  &:hover,
  &:active {
    color: ${theme.color.primary.default} !important;
    background-color: transparent;
  }
`;

export const StyledWrapTertiary = styled.div`
  > div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 24px;
    color: ${theme.color.gray.gray700};
  }

  > div > p {
    grid-column-start: 1;
    grid-column-end: 3;
    padding-bottom: 12px;
    line-height: 20px;
  }

  > div > span {
    :first-of-type {
      color: ${theme.color.gray.gray400};
    }

    button {
      height: 100%;
      color: ${theme.color.gray.gray700};

      span {
        text-decoration: underline;
      }
    }

    &:not(:nth-of-type(1)) {
      position: relative;
    }
  }
`;

export const styledIcon = `
  justify-content: center;

  min-height: 3.2rem;
  width: 3.2rem;
  line-height: 2rem;
  background: #FFFFFF;
  
  &:disabled {
    background-color: transparent !important;
    svg path {
    fill: #B2BECF;
    }
  }
  
  
  &:focus {
    background: #FFFFFF;
  }
  
  &:hover {
    background-color: rgb(243, 243, 243);
   
  }
  
  &:active {
    background-color: #E9E9E9;
  }
  
`;

export const styledIconWithText = `
  gap: 10px;
  padding: 4px 10px;

  justify-content: center;

  min-height: 3.2rem;
  line-height: 2rem;
  background: #FFFFFF;
  
  &:disabled {
    background-color: transparent !important;
    svg path {
    fill: #B2BECF;
    }
  }
  
  
  &:focus {
    background: #FFFFFF;
  }
  
  &:hover {
    background-color: rgb(243, 243, 243);
   
  }
  
  &:active {
    background-color: #E9E9E9;
  }
  
`;

export const styledModal1 = `
  min-height: 4.8rem;
  padding: 0 .8rem;
  font-weight: 700;
  font-size: 14px;
  line-height: 128.9%;
  color: #FFFFFF !important;
  background: ${theme.color.primary.default};
  
  &.unsubscribe {
    background: ${theme.color.gray.gray800};
    &:disabled, &:hover {
      background: ${theme.color.gray.gray200} !important;
    }
  }
  
  &:disabled {
    background: ${theme.color.primary.disabled};
    
    &:hover {
    background: #CBD6FE;
    }
  }
  
  &:focus {
    color: #FFFFFF !important;
    background: ${theme.color.primary.focus};
  }
    
  
  &:hover {
    background-color: ${theme.color.primary.hover};
  }
  
  &:active {
    background-color: ${theme.color.primary.active};
  }
  
  &.withdrawal {
    background: ${theme.color.error.default};
    
    &:disabled {
      background: ${theme.color.error.disabled};
    }
    
    &:hover {
      background: ${theme.color.error.hover};
    }

  }
`;

export const styledModalSecondary = `
  min-height: 4.8rem;
  padding: 0 .8rem;
  font-weight: 700;
  font-size: 14px;
  line-height: 128.9%;
  color: #FFFFFF !important;
  background: ${theme.color.gray.gray800};
  
  &:disabled {
    background: ${theme.color.primary.disabled};
    
    &:hover {
    background: #CBD6FE;
    }
  }
  
  &:focus {
    color: #FFFFFF !important;
    background: ${theme.color.gray.gray800};
  }
  
  
  &:hover {
    background-color: ${theme.color.gray.gray800};
  }
  
  &:active {
    background-color: ${theme.color.gray.gray800};
  }

`;

export const styledModal2 = `
  min-height: 4.8rem;
  padding: 0 .8rem;
  border: 1px solid #3462FF !important;
  font-weight: 400;
  font-size: 14px;
  color: #3462FF !important;
  line-height: 128.9%;
  background: #FFFFFF;
  
  &.unsubscribe {
    border: 1px solid #515151 !important;
    color: #515151 !important;
    
    &:active {
      color: #515151 !important;
    }
  }
  
  &:focus {
    color: #3462FF !important;
    background: #FFFFFF;
  }
  
  &:hover {
    background: #FFFFFF;
  }
  
  &.withdrawal {
    border: 1px solid #515151 !important;
    color: #515151 !important;
    
    &:focus {
    color: #515151 !important;
    background: #FFFFFF;
  }
  
  &:hover {
    background: #FFFFFF;
  }
  
  &:active {
    background-color: #FFFFFF;
  }
  }
`;

export const styledForm1 = `
  height: 36px;
  background: ${theme.color.primary.default};
  border-radius: 6px;
  color: #FFFFFF !important;
  
  &:focus {
    color: #FFFFFF !important;
    background: #474E60;
  }
    
  
  &:hover {
    background-color: #566078;
  }
  
  &:active {
    background-color: #3B404E;
  }
`;

const styledOutline = `
  padding: 0 1rem;
  border: 1px solid ${theme.color.gray.gray500} !important;
  font-weight: 700;
  font-size: 12px;
  line-height: 128.9%;
  color:  ${theme.color.gray.gray500};
  background-color: transparent;
  
  min-width: 70px;
  
  &:focus {
    color: #000;
    background-color: transparent;
  }
  
  &:hover {
    color:  ${theme.color.gray.gray500} !important;
    background-color: transparent;
  }
  
  &:active {
    color: inherit;
    background-color: transparent;
  }
`;
