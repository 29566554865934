import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import theme from '@/assets/styles/theme';
import classNames from 'classnames';
import { toDateTime } from '@/lib/util/format';
import { ReactComponent as IconCalendar } from '@/assets/images/icon/icon-calendar-black.svg';
import { ISubscriptionData } from '@/pages/Organizations/hooks/useOrganizationsQuery';

type CardProps = {
  id: number;
  isSelected: boolean;
  isActive: boolean;
  credit?: {
    startAt: string;
    endAt: string;
  };
  subscription?: ISubscriptionData;
  displayName: string;
  functionGroups?: string[];
  onClick: (id: number) => void;
};

function OrganizationCard({
  id,
  isSelected = false,
  isActive,
  credit,
  subscription,
  displayName,
  functionGroups,
  onClick,
}: CardProps) {
  const resolvedPeriod = useMemo(() => {
    if (subscription) {
      return `구독기간: ${toDateTime(subscription.periodOfUse.startAt, 'YYYY.MM.DD')}~${toDateTime(
        subscription.periodOfUse.endAt,
        'YYYY.MM.DD',
      )}${isActive ? '' : '(종료)'}`;
    }

    if (credit) {
      return `구독기간: ${toDateTime(credit.startAt, 'YYYY.MM.DD')}~${toDateTime(
        credit.endAt,
        'YYYY.MM.DD',
      )}${isActive ? '' : '(종료)'}`;
    }

    return `구독기간: 만료됨`;
  }, []);

  return (
    <StyledCard
      className={classNames({ selected: isSelected, isActive })}
      onClick={() => onClick(id)}
    >
      <section>
        <p>{displayName}</p>
        <small>
          <IconCalendar />
          {resolvedPeriod}
        </small>
      </section>
      <div>
        {functionGroups &&
          functionGroups.map((tag, index) => {
            return <span key={index}>{`#${tag} `}</span>;
          })}
      </div>
    </StyledCard>
  );
}

export default OrganizationCard;

const StyledCard = styled.button`
  width: 100%;
  height: auto;
  border-radius: 6px;
  border: 1px solid ${theme.color.gray.gray400} !important;

  margin-bottom: 2rem;

  &:hover {
    border: 2px solid ${theme.color.primary.default} !important;
    cursor: pointer;
  }

  &.selected {
    border: 2px solid ${theme.color.primary.default} !important;
  }

  &.isActive {
    section {
      p {
        color: ${theme.color.primary.default};
      }
    }
  }

  section {
    background-color: ${theme.color.menu.backB};

    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;

    padding: 16px 12px;

    border-top-left-radius: 6px;
    border-top-right-radius: 6px;

    p {
      display: flex;
      justify-content: flex-start;

      font-size: 1.6rem;

      font-weight: 500;
    }

    small {
      svg {
        margin-right: 3px;
      }

      font-size: 13px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 6px 0 0 0;

      color: ${theme.color.gray.gray900};
    }
  }

  display: flex;
  flex-direction: column;

  div {
    padding: 16px 12px;
  }

  > div {
    width: 100%;

    display: flex;
    flex-wrap: wrap;

    span {
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      color: ${theme.color.gray.gray900};
      margin-right: 0.5rem;
    }
  }
`;
