import React from 'react';
import ButtonToOpenAside from '@/components/layout/header/ButtonToOpenAside';
import { styledPageHeader, StyledPageHeader } from './styleOfLayout';
import { useRecoilState } from 'recoil';
import { hideNavState } from '@/atoms/navState';
import { mobileSizeState } from '@/atoms/mobileSizeState';

interface TPageHeaderProps extends React.HTMLAttributes<any> {
  header: React.ReactNode;
  addContent?: React.ReactNode;
  isNoteDetail?: boolean;
}

const PageHeader = (props: TPageHeaderProps) => {
  const [hideNav, setHideNav] = useRecoilState(hideNavState);
  const [mobileSize] = useRecoilState(mobileSizeState);

  const { header, addContent, children, isNoteDetail, ...rest } = props;

  const onClick = () => {
    setHideNav(false);
  };

  return (
    <div
      {...rest}
      className={`page-header flex-none flex  items-center ${isNoteDetail ? 'detail' : ''}`}
      css={styledPageHeader}
    >
      <StyledPageHeader className="flex-1" hideNav={hideNav} mobileSize={mobileSize}>
        <ButtonToOpenAside className="btn-open-aside" onClick={onClick} />
        {header}
        {addContent ? addContent : null}
      </StyledPageHeader>
      {children}
    </div>
  );
};
export default PageHeader;

/** 페이지 상단 내용 - 제목 관련 추가 내용 **/
interface TPageHeaderAddContentProps extends React.HTMLAttributes<HTMLElement> {}

export const PageHeaderAddContent = (props: TPageHeaderAddContentProps) => {
  const { children, className, ...rest } = props;
  return (
    <div className={`wrap-info mt-[1.1rem] ${className || ''}`} {...rest}>
      {children}
    </div>
  );
};

/** 페이지 상단 내용 - 제목 관련이 아닌 내용 **/
interface TPageHeaderAsideProps extends React.HTMLAttributes<HTMLElement> {}

export const PageHeaderAside = (props: TPageHeaderAsideProps) => {
  const { children, ...rest } = props;
  return (
    <aside className="wrap-aside flex-none flex flex-wrap items-center" {...rest}>
      {children}
    </aside>
  );
};
