import { useLocation } from 'react-router-dom';
import React, { useEffect, useMemo, useState } from 'react';
import IconLibrary from '@/assets/images/gnb/icon-library.svg';
import ImgTrash from '@/assets/images/gnb/icon-trash.svg';
import IconInquiryEmail from '@/assets/images/gnb/icon-Inquiry-email.svg';
import IconNotice from '@/assets/images/gnb/icon-notice.svg';
import IconUserGuide from '@/assets/images/gnb/icon-user-guide.svg';
import IconCodes from '@/assets/images/gnb/icon-manager-codes.svg';
import IconManaging from '@/assets/images/gnb/icon-manager-managing.svg';
import IconStatistics from '@/assets/images/gnb/icon-manager-statistics.svg';
import GnbItem from './GnbItem';
import classNames from 'classnames';
import { Divider, Typography } from 'antd';
import styled from '@emotion/styled';
import { useRecoilState } from 'recoil';
import { organizationState } from '@/atoms/organizationState';

const { Text } = Typography;

const gngNoteData = [
  { name: '전체 자막', icon: IconLibrary, to: '/notes?sort_key=id' },
  { name: '휴지통', icon: ImgTrash, to: '/trash-notes?sort_key=deleted_at' },
];

const gngManageData = [
  { name: '계정 관리', icon: IconManaging, to: '/organizations/users' },
  { name: '계정 통계', icon: IconStatistics, to: '/organizations/statistics' },
  {
    name: '초대 코드 관리',
    icon: IconCodes,
    to: '/organizations/invite-codes?page=1&perPage=10',
  },
];

const gngPastryManageData = [
  { name: '계정 통계', icon: IconStatistics, to: '/organizations/statistics' },
];

const gngUserSupportData = [
  { name: '1:1 문의', icon: IconInquiryEmail, className: 'channel-service' },
  { name: '공지사항', icon: IconNotice, to: '/notice' },
  { name: '사용 가이드', icon: IconUserGuide, to: '/user-guide' },
];

const GlobalNavigation = () => {
  const location = useLocation();
  const [currentPathname, setCurrentPathname] = useState(location.pathname);

  const [organizationInfo] = useRecoilState(organizationState);

  useEffect(() => {
    setCurrentPathname(location.pathname);
  }, [location]);

  const gnbList = useMemo(() => {
    if (organizationInfo.isManage && organizationInfo.functionGroup.name !== 'support_manager') {
      return gngManageData;
    }

    if (organizationInfo.isManage && organizationInfo.functionGroup.name === 'support_manager') {
      return gngPastryManageData;
    }

    return gngNoteData;
  }, [organizationInfo]);

  return (
    <StyledGlobalNavigation>
      <ul>
        {gnbList.map((item) => {
          return (
            <GnbItem
              key={item.name}
              to={item.to}
              name={item.name}
              icon={item.icon}
              className={classNames({
                'is-active': currentPathname.includes(item.to.split('?')[0]),
              })}
            />
          );
        })}
      </ul>
      <Divider />
      <ul>
        {gngUserSupportData.map((item) => {
          return (
            <GnbItem
              key={item.name}
              to={item.to}
              name={item.name}
              icon={item.icon}
              className={classNames(
                { 'is-active': item.to && currentPathname.includes(item.to) },
                item.className,
              )}
            />
          );
        })}
      </ul>
    </StyledGlobalNavigation>
  );
};
export default GlobalNavigation;

const StyledGlobalNavigation = styled.div`
  ul {
    margin-top: 8px;
  }
`;
