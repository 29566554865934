import React, { useCallback, useEffect, useState } from 'react';
import Modal from '@/components/popup/Modal';
import SovoroUpload from '@/components/upload/SovoroUpload';
import { UploadProps } from 'antd/es/upload/interface';
import { RcFile, UploadFile } from 'antd/lib/upload';
import { Upload } from 'antd';
import { getSovoroStorage } from '@/lib/auth/firebase-auth';
import { deleteObject, ref, uploadBytesResumable } from 'firebase/storage';
import { useRecoilState } from 'recoil';
import { authState } from '@/atoms/authState';
import styled from '@emotion/styled';
import SovoroSelect from '@/components/select/Select';
import { ReactComponent as ImgCloseGray } from '@/assets/images/button/icon-close-gray.svg';
import useUploadMutation, { IUploadData } from '@/pages/Notes/hooks/useUploadMutation';
import useNetworkMonitoring from '@/hooks/useNetworkMonitoring';
import { getInfo } from 'react-mediainfo';
import { checkFileDuration } from '@/lib/util/duration';

export type FileInfoType = {
  title: string;
  duration: number;
  uid: string;
  key: string;
  size: number;
  originName: string;
};

export type FileUploadError = {
  type: 'size' | 'duration' | 'accept' | 'zero' | null;
  title: string;
  description1: string;
  description2?: string;
};

interface IFileUploadModalProps {
  onCancel: () => void;
}

const matchTypes = [
  'avi',
  'mp4',
  'mov',
  'flv',
  'mkv',
  'aac',
  'wav',
  'mp3',
  'm4a',
  'ac3',
  'flac',
  'ogg',
];

function FileUploadModal({ onCancel }: IFileUploadModalProps) {
  const [authInfo] = useRecoilState(authState);
  const onLine = useNetworkMonitoring();

  const [lang, setLang] = useState('enko');

  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [fileInfo, setFileInfo] = useState<FileInfoType>({
    title: '',
    duration: 0,
    uid: '',
    key: '',

    size: 0,
    originName: '',
  });
  const [uploadTask, setUploadTask] = useState<any>(null);

  const [percent, setPercent] = useState<number>(0);

  const [error, setError] = useState<FileUploadError>({
    type: null,
    title: '',
    description1: '',
    description2: '',
  });

  const { mutate, isLoading, isSuccess } = useUploadMutation();

  useEffect(() => {
    isSuccess && onCancel();
  }, [isSuccess, onCancel]);

  const props: UploadProps = {
    onDrop: (event) => {
      const { type, name } = event.dataTransfer.files[0];

      const file = new File(['file content'], name, { type: type });

      const fileName = file.name;
      const fileExtension = fileName.split('.').pop()!;

      const accept = matchTypes.includes(fileExtension);

      if (!accept) {
        setError({
          type: 'accept',
          title: '지원하는 형식의 파일이 아닙니다.',
          description1: '오디오: mp3, aac, ac3, ogg, flac, wav, m4a',
          description2: '비디오: avi, mp4, mov, flv, mkv',
        });
      }
    },
    beforeUpload: async (file: RcFile) => {
      if (file.size <= 0) {
        setError({
          type: 'zero',
          title: '처리할 수 없는 파일입니다.',
          description1: '파일이 비어있거나, 올바른 형식이 아닐 수 있습니다.',
          description2: '파일 상태를 확인해주세요.',
        });
      }

      const aacDuration = file.type === 'audio/aac' && (await checkFileDuration(file, 'audio'));

      const { media } = await getInfo(file);

      await setPercent(0);

      const duration = aacDuration ? aacDuration : Number(media.track[0].Duration);

      const ok = duration <= 7200 && file.size <= 2147483648;

      if (duration > 7200) {
        setError({
          type: 'duration',
          title: '파일 길이를 초과하였습니다.',
          description1: '최대 2시간 길이의 파일만 가능합니다.',
          description2: '다시 시도해주세요.',
        });
      }

      if (file.size > 2147483648) {
        setError({
          type: 'size',
          title: '파일 용량을 초과하였습니다.',
          description1: '최대 2GB 이하의 파일만 가능합니다.',
          description2: '다시 시도해주세요.',
        });
      }

      return ok || Upload.LIST_IGNORE;
    },
    onChange: async ({ fileList }) => {
      setFileList(fileList);
    },
    defaultFileList: fileList,
    multiple: false,
    listType: 'picture',
    accept: '.avi, .mp4, .mov, .flv, .mkv, .aac, .wav, .mp3, .m4a, .ac3, .flac, .ogg',
    showUploadList: {
      removeIcon: <ImgCloseGray />,
    },
    onRemove: async (file: any) => {
      const storage = getSovoroStorage();
      const extension = file.name.split('.').pop();
      const key = `files/${authInfo.email}/upload/${file.uid}.${extension}`;
      const storageRef = ref(storage, key);

      if (uploadTask) {
        uploadTask.cancel();
      }

      try {
        await deleteObject(storageRef);
        await setFileInfo({
          title: '',
          duration: 0,
          uid: file.uid,
          key: key,
          size: 0,
          originName: '',
        });
        await setPercent(0);
      } catch (e) {
        console.error('Error:', e);
      }
    },
    customRequest: async ({ file, onSuccess }: any) => {
      const storage = getSovoroStorage();

      const extension = file.name.split('.').pop();

      const key = `files/${authInfo.email}/upload/${file.uid}.${extension}`;
      const storageRef = ref(storage, key);

      const aacDuration = file.type === 'audio/aac' && (await checkFileDuration(file, 'audio'));

      const { media } = await getInfo(file);

      await setPercent(0);

      const duration = aacDuration ? aacDuration : Number(media.track[0].Duration);

      const data = {
        title: file.name,
        duration: duration,
        uid: `${file.uid}.${extension}`,
        originName: file.name,
        size: file.size,
      };

      setError({
        type: null,
        title: '',
        description1: '',
        description2: '',
      });

      setFileInfo(data as FileInfoType);

      const metadata = {
        customMetadata: {
          status: 'ready',
        },
      };

      try {
        const task = uploadBytesResumable(storageRef, file, metadata);

        setUploadTask(task);

        await task.on(
          'state_changed',
          (snapshot: any) => {
            const percent = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setPercent(Math.floor(percent));
          },
          (error: any) => {
            console.error(error);
          },
          async () => {
            await onSuccess(null, file);
          },
        );
      } catch (e) {}
    },
  };

  const onFileNoteSubmit = useCallback(async () => {
    const data = {
      title: fileInfo.title,
      duration: fileInfo.duration,
      language: lang,
      size: fileInfo.size,
      origin_name: fileInfo.originName,
      file_uid: fileInfo.uid,
    } as IUploadData;

    await mutate({
      value: data,
    });
  }, [fileInfo, lang, mutate]);

  const onFileNoteCancel = useCallback(async () => {
    if (uploadTask) {
      uploadTask.cancel();
    }

    const storage = getSovoroStorage();
    const storageRef = ref(storage, fileInfo.key);

    try {
      await deleteObject(storageRef);
      await setFileInfo({
        title: '',
        duration: 0,
        key: '',
        uid: '',
        size: 0,
        originName: '',
      });
      await setPercent(0);
    } catch (e) {
      console.error('Error:', e);
    }

    await onCancel();
  }, [fileInfo, onCancel, uploadTask]);

  const onLanguageChange = useCallback(
    (value: string) => {
      setLang(value);
    },
    [setLang],
  );

  return (
    <Modal
      open={true}
      type="left"
      title="음성 • 영상 파일 자막 만들기"
      okText="만들기"
      closable={false}
      onCancel={onFileNoteCancel}
      onOk={onFileNoteSubmit}
      cancelText="취소"
      isButtonDisabled={percent !== 100 || fileList.length === 0 || isLoading}
    >
      <>
        <SovoroUpload
          onLine={onLine}
          fileInfo={fileInfo!}
          error={error}
          percent={percent}
          fileList={fileList}
          {...props}
        />
      </>

      <Languages>
        <span>언어 설정</span>
        <SovoroSelect
          placement="bottomLeft"
          defaultValue={lang}
          value={lang}
          onChange={onLanguageChange}
          dropdownStyle={{
            height: 40,
          }}
          options={[
            {
              label: '한국어 + 영어',
              value: 'enko',
            },
            {
              label: '한국어',
              value: 'ko-KR',
            },
            {
              label: '영어',
              value: 'en-US',
            },
          ]}
          dropdownState={false}
          bordered={true}
        />
      </Languages>
    </Modal>
  );
}

export default FileUploadModal;

const Languages = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 3rem;

  .ant-select-selector {
    height: 40px !important;
    align-items: center;
  }

  .ant-select {
    margin: 0 !important;
    width: 120px;
  }

  .ant-select-item-option-content {
    text-align: right !important;
  }
`;
