import classNames from 'classnames';
import React, { useMemo } from 'react';
import GlobalNavigation from '@/components/layout/aside/GlobalNavigation/GlobalNavigation';
import { styledLayoutAside, styledLayoutAsideDim } from '@/components/layout/styleOfLayout';
import AccountInfo from '@/components/layout/aside/AccountInfo/AccountInfo';
import { useRecoilState, useRecoilValue } from 'recoil';
import { authState } from '@/atoms/authState';
import styled from '@emotion/styled';
import { organizationState } from '@/atoms/organizationState';
import Notifications from '@/pages/Notifications/Notifications';
import OrganizationPlanCard from '@/components/card/OrganizationPlanCard';
import { toDateTime } from '@/lib/util/format';
import useFunctionGroupCheck from '@/hooks/useFunctionGroupCheck';
import usePrivateChannelService from '@/hooks/usePrivateChannelService';
import SovoroLogo from '@/components/layout/LayoutAside/SovoroLogo';
import { isMobileState } from '@/atoms/isMobileState';
import { useUserQuery } from '@/hooks/useUserQuery';
import CreateNewSubtitle from '@/components/layout/aside/CreateNewSubtitle/CreateNewSubtitle';

interface TInternalProps extends React.HTMLAttributes<HTMLElement> {
  isHidden?: boolean;
  mobileSize: boolean;
  clickGnb?: () => void;
  signOut?: () => void;
}

const LayoutAside = ({
  isHidden = false,
  mobileSize,
  clickGnb = () => {},
  signOut = () => {},
}: TInternalProps) => {
  const { openWorkflow } = usePrivateChannelService();

  const isMobile = useRecoilValue(isMobileState);

  const [authInfo] = useRecoilState(authState);
  const [workspaceInfo] = useRecoilState(organizationState);
  const { email } = authInfo;
  const { id: workspaceId, displayName: workspaceDisplayName } = workspaceInfo;
  const webSidebarPlan = useFunctionGroupCheck('webSidebarPlan');
  const mobileWebSidebarPlan = useFunctionGroupCheck('mobileWebSidebarPlan');
  const noteYoutube = useFunctionGroupCheck('noteYoutube');
  const webCaption = useFunctionGroupCheck('webCaption');
  const fileUpload = useFunctionGroupCheck('fileUpload');

  const { data: userData } = useUserQuery();

  const resolvedPeriod = useMemo(() => {
    if (workspaceInfo?.subscription) {
      return `${toDateTime(
        workspaceInfo?.subscription?.periodOfUse?.startAt,
        'YYYY.MM.DD',
      )}~${toDateTime(workspaceInfo?.subscription?.periodOfUse?.endAt, 'YYYY.MM.DD')}`;
    }

    if (workspaceInfo?.credit) {
      return `${toDateTime(workspaceInfo.credit?.startAt, 'YYYY.MM.DD')}~${toDateTime(
        workspaceInfo?.credit?.endAt,
        'YYYY.MM.DD',
      )}`;
    }

    return '';
  }, [workspaceInfo]);

  return (
    <>
      <StyledLayoutAside
        className={classNames(
          `flex flex-col flex-none ${mobileSize ? 'transition mobile' : 'transition-[margin]'}`,
          { isHidden: isHidden },
        )}
        css={styledLayoutAside}
      >
        <LayoutAsideHeader>
          <SovoroLogo onClick={clickGnb} />
          <AccountInfo
            displayName={userData?.displayName || ''}
            email={email || ''}
            workspaceDisplayName={workspaceDisplayName || ''}
            mobileSize={mobileSize}
            signOut={signOut}
          />
        </LayoutAsideHeader>

        {(noteYoutube || fileUpload || webCaption) && !isMobile && (
          <CreateNewSubtitle
            mobileSize={mobileSize}
            fileUpload={fileUpload}
            webCaption={webCaption}
            noteYoutube={noteYoutube}
          />
        )}

        <LayoutAsideNav isMobile={isMobile}>
          {workspaceId !== 0 && <GlobalNavigation />}
        </LayoutAsideNav>

        <LayoutAsideFooter>
          {(webSidebarPlan || (mobileSize && mobileWebSidebarPlan)) && (
            <OrganizationPlanCard
              subscriptionType={workspaceInfo?.subscription?.type}
              openWorkflow={openWorkflow}
              planName={workspaceInfo?.credit ? workspaceInfo?.credit.productName : '없음'}
              isSignupPromotionProduct={workspaceInfo?.credit?.isSignupPromotionProduct}
              productBasicDuration={workspaceInfo?.credit?.productBasicDuration}
              period={resolvedPeriod}
            />
          )}

          <Notifications />
        </LayoutAsideFooter>
      </StyledLayoutAside>

      {/* 모바일 - dim 영역 클릭시 메뉴 영역 닫힘 */}
      {!isHidden && mobileSize && <div css={styledLayoutAsideDim} onClick={clickGnb} />}
    </>
  );
};
export default LayoutAside;

const StyledLayoutAside = styled.aside``;

const LayoutAsideHeader = styled.header`
  padding: 4.6rem 1.6rem 2.3rem;
`;

const LayoutAsideNav = styled.nav<{ isMobile: boolean }>`
  flex: 1;
  overflow-y: ${(props) => (props.isMobile ? 'scroll' : 'hidden')};

  padding: 1rem 1.6rem 1rem;

  &::-webkit-scrollbar {
    width: 16px;
    margin-right: 4px;
  }

  /* 스크롤바 트랙 */
  &::-webkit-scrollbar-track {
    background-color: ${(props) => props.theme.color.menu.backC};
  }

  /* 스크롤바 핸들 */
  &::-webkit-scrollbar-thumb {
    background-color: #d9d9d9;
    border-radius: 2px;
    border: 6px solid ${(props) => props.theme.color.menu.backC};
  }

  /* 스크롤바 핸들 호버 시 */
  &::-webkit-scrollbar-thumb:hover {
    background-color: #d9d9d9;
  }

  &:hover {
    overflow-y: scroll;

    padding: 1rem 0 1rem 1.6rem;
  }
`;

const LayoutAsideFooter = styled.footer`
  position: sticky;
  bottom: 0;

  background-color: ${(props) => props.theme.color.white.default};
`;
