import { toDateTime } from '@/lib/util/format';
import useNotesInfiniteQuery, { INotesData, SortDirection } from '@/hooks/useNotesInfiniteQuery';
import { descriptionStr, NoteRecordingSttStatus } from '@/pages/NoteDetail/hooks/useNoteQuery';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

interface INotesGroup {
  rows: INotesData[];
  total: number;
  youtubeTotal: number;
  textTotal: number;
  recordingTotal: number;
}

const defaultFilter = {
  page: 1,
  perPage: 20,
  sortDirection: SortDirection.DESC,
};

export const queryKey = ['notes'];

const select = (recentNotesData: any) => {
  const pages = recentNotesData.pages.map((recentNoteGroup: INotesGroup) => {
    const rows = recentNoteGroup.rows.map((note) => {
      const description = descriptionStr[note.recordingSttStatus] || note.summary;

      return {
        ...note,
        link: `/notes/${note.id}`,
        title: note.title,
        description,
        durationNum: (note.file && note.file.duration) || (note.media && note.media.duration),
        duration:
          (note.file && note.file.durationText) ||
          (note.media && dayjs.duration(note.media.duration, 'seconds').format('HH:mm:ss')),
        isError:
          note.recordingSttStatus === NoteRecordingSttStatus.UPLOADING_FAILED ||
          note.recordingSttStatus === NoteRecordingSttStatus.ERROR,
        isNew:
          (note.recordingSttStatus === NoteRecordingSttStatus.COMPLETED && !note.read) ||
          (note.recordingSttStatus === NoteRecordingSttStatus.NONE && !note.read),
        checked: note.checked || false,
        updatedAt: toDateTime(note.updatedAt),
      };
    });

    return {
      ...recentNoteGroup,
      rows,
    };
  });

  return {
    ...recentNotesData,
    pages,
  };
};

export const useNotesQuery = () => {
  return useNotesInfiniteQuery(queryKey, defaultFilter, select);
};
