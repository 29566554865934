import React from 'react';
import { css, Global } from '@emotion/react';
import { GlobalStyles as BaseStyles } from 'twin.macro';
import theme from './theme';
import {
  antdStyleCommon,
  antdStyleMessage,
  antdStyleSelect,
} from '@/assets/styles/customizeStyle/antdStyle';
import { antdStyleForm } from '@/assets/styles/customizeStyle/antdStyleForm';

const customMediaQuery = (maxWidth: number): string => {
  return `@media (max-width: ${maxWidth}px)`;
};
const customMediaQueryMin = (maxWidth: number): string => {
  return `@media (min-width: ${maxWidth}px)`;
};

export const media = {
  custom: customMediaQuery,
  customMin: customMediaQueryMin,
  pc: customMediaQueryMin(769),
  mobile: customMediaQuery(768),
  mobile2: customMediaQuery(375),
};

export const globalStyles = css`
  * {
    font-family: 'Noto Sans KR', sans-serif !important;
  }

  html {
    font-size: 62.5%;
  }

  body {
    overflow: hidden;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 128.91%;
    color: ${theme.color.gray.gray900};
    word-break: keep-all;
  }

  #root {
    height: 100%;
  }

  .text-size1 {
    font-size: 1rem;
  }

  .text-size2 {
    font-size: 1.2rem;
  }

  .text-size3 {
    font-size: 1.6rem;
  }

  .text-size4 {
    font-size: 1.8rem;
  }

  .text-size5 {
    font-size: 2rem;
  }

  .text-light {
    font-weight: 300;
  }

  .text-regular {
    font-weight: 400;
  }

  .text-medium {
    font-weight: 500;
  }

  .text-bold {
    font-weight: 700;
  }

  .text-point,
  .required-mark {
    font-style: normal;
    color: ${theme.color.error.default};
  }

  .line-break {
    display: block;
  }

  .ant-tooltip-arrow {
    display: none !important;
  }

  .ant-btn-default:not(:disabled):hover {
    color: #191919;
  }
`;

const GlobalStyles = () => (
  <>
    <BaseStyles />
    <Global
      styles={[globalStyles, antdStyleMessage, antdStyleForm, antdStyleSelect, antdStyleCommon]}
    />
  </>
);

export default GlobalStyles;
