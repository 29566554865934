import { Form, FormProps } from 'antd';
import React from 'react';
import Button from '@/components/button/Button';
import styled from '@emotion/styled';
import theme from '@/assets/styles/theme';

interface Props extends FormProps {
  name: string;
  children: React.ReactElement;
  descriptions?: string[];
  divider?: boolean;
}

function Register(props: Props) {
  const { form, onFinish, name, children, descriptions, divider } = props;

  return (
    <RegisterContainer>
      <Form form={form} onFinish={onFinish}>
        <Form.Item name={name}>{children}</Form.Item>
        <Form.Item shouldUpdate>
          {() => (
            <Button htmlType="submit" disabled={!form?.getFieldValue(name)}>
              등록
            </Button>
          )}
        </Form.Item>
      </Form>
      {descriptions && (
        <ul className={divider ? 'divider' : ''}>
          {descriptions.map((description, index) => {
            return <li key={index}>{description}</li>;
          })}
        </ul>
      )}
    </RegisterContainer>
  );
}

export default Register;

const RegisterContainer = styled.div`
  margin-top: 32px;
  width: 100%;

  form {
    display: grid;
    grid-template-columns: auto 100px;

    div:nth-of-type(2) {
      display: flex;
      justify-content: flex-end;
    }
  }

  input {
    text-align: left !important;
    font-weight: normal !important;
  }

  button {
    width: 80px;
  }

  ul {
    padding: 1.6rem 3rem 2.5rem;
    font-size: 14px;
    line-height: 24px;
    list-style: disc;

    &.divider {
      border-bottom: 1px solid ${theme.color.gray.gray100};
    }

    word-break: break-word;
    word-wrap: break-word;
  }
`;
