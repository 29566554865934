import React from 'react';
import Button from '@/components/button/Button';
import { ReactComponent as ImgLive } from '@/assets/images/button/icon-live.svg';
import { ReactComponent as ImgYoutube } from '@/assets/images/button/icon-youtube.svg';
import { ReactComponent as ImgFile } from '@/assets/images/button/icon-file.svg';
import styled from '@emotion/styled';
import theme from '@/assets/styles/theme';

interface CreateSubtitleContentsProps {
  webCaption: boolean;
  noteYoutube: boolean;
  fileUpload: boolean;
  onYoutubeClick: () => void;
  onFileClick: () => void;
  onWebCaptionClick: () => void;
}

function CreateSubtitleContents({
  webCaption,
  noteYoutube,
  fileUpload,
  onYoutubeClick,
  onFileClick,
  onWebCaptionClick,
}: CreateSubtitleContentsProps) {
  return (
    <SubtitleContentsContainer>
      {webCaption && (
        <Button icon={<ImgLive />} onClick={onWebCaptionClick}>
          실시간 자막 만들기
        </Button>
      )}
      {noteYoutube && (
        <Button icon={<ImgYoutube />} onClick={onYoutubeClick}>
          유튜브 자막 만들기
        </Button>
      )}
      {fileUpload && (
        <Button icon={<ImgFile />} onClick={onFileClick}>
          음성 • 영상 파일 자막 만들기
        </Button>
      )}
    </SubtitleContentsContainer>
  );
}

export default CreateSubtitleContents;

const SubtitleContentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  button {
    padding: 8px 24px;
    line-height: 24px;
    min-height: auto;
    height: 40px;

    background-color: transparent;

    &:hover,
    &:focus {
      :hover {
        background-color: ${theme.color.menu.backB} !important;
        border-radius: 0;
      }
    }

    svg {
      width: 22px;
      height: auto;
      margin-right: 10px;
    }
    span {
      color: ${theme.color.gray.gray900};
      font-weight: 400;
      font-size: 16px;
    }
  }
`;
