import styled from '@emotion/styled';
import React, { useState } from 'react';
import { ReactComponent as IconRightArrow } from '@/assets/images/button/icon-right-arrow.svg';
import Popover from '@/components/antd/Popover';
import { css } from '@emotion/react';
import AccountInfoMenu from './AccountInfoMenu';

interface AccountInfoProps {
  displayName: string;
  email: string;
  workspaceDisplayName: string;
  mobileSize?: boolean;
  signOut?: () => void;
}

function AccountInfo({
  displayName,
  email,
  workspaceDisplayName,
  mobileSize = false,
  signOut = () => {},
}: AccountInfoProps) {
  const [open, setOpen] = useState(false);

  const onOpenChange = (open: boolean) => {
    setOpen(open);
  };

  return (
    <AccountPopover
      open={open}
      placement={mobileSize ? 'bottom' : 'rightTop'}
      content={<AccountInfoMenu setPopoverOpen={setOpen} signOut={signOut} />}
      arrow={false}
      trigger="click"
      onOpenChange={onOpenChange}
    >
      <StyledAccountInfo open={open} mobileSize={mobileSize}>
        <div>
          <AccountDisplayName>{displayName}</AccountDisplayName>
          <AccountEmail className="text-ellipsis overflow-hidden">{email}</AccountEmail>
          <AccountWorkspace>
            <span>{workspaceDisplayName}</span>
          </AccountWorkspace>
        </div>
        <AccountArrow />
      </StyledAccountInfo>
    </AccountPopover>
  );
}

export default AccountInfo;

const AccountPopover = styled(Popover)`
  .ant-popover-inner {
    box-sizing: border-box;
    padding: 16px 0 24px 0;

    width: 208px;
    background: ${(props) => props.theme.color.white};

    border: 1px solid ${(props) => props.theme.color.gray.gray300};
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
  }
`;

const accountInfoStyle = (props: { open: boolean; mobileSize: boolean }) =>
  css`
    > svg {
      ${props.mobileSize ? `transform: rotate(90deg);` : ''}
      ${props.mobileSize && props.open ? `transform: rotate(270deg);` : ''}
      ${!props.mobileSize && props.open ? `transform: rotate(180deg);` : ''}
    }
  `;

const StyledAccountInfo = styled.div`
  margin: 0 auto 0;

  width: 208px;
  position: relative;
  border: 1px solid ${(props) => props.theme.color.gray.gray300};
  border-radius: 4px;
  background: ${(props) => (props.open ? props.theme.color.menu.backB : props.theme.color.white)};

  cursor: pointer;

  ${accountInfoStyle}

  :hover {
    background: ${(props) => props.theme.color.menu.backB};
  }

  > div {
    display: flex;
    flex-direction: column;
    margin-left: 16px;
    margin-top: 12px;
    margin-bottom: 8px;

    width: 152px;
  }
`;

const AccountDisplayName = styled.p`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: normal !important;
  color: ${(props) => props.theme.color.darkGray.default};
`;

const AccountEmail = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: normal;
  color: ${(props) => props.theme.color.gray.gray600};
  margin: 4px 0;
`;

const AccountWorkspace = styled.div`
  > span {
    padding: 2px 6px;
    background-color: ${(props) => props.theme.color.menu.backB};
    border-radius: 2px;

    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 15px;
    color: ${(props) => props.theme.color.primary.hover};
  }

  margin: 4px 0;
`;

const AccountArrow = styled(IconRightArrow)`
  position: absolute;
  top: 45px;
  right: 19px;

  stroke: ${(props) => props.theme.color.gray.gray300};

  transition: transform 0.3s;
`;
