import React, { useCallback, useEffect, useRef } from 'react';
import { useNotificationQuery } from '@/pages/Notifications/hooks/useNotificationsHistoryQuery';
import { useVirtualizer, VirtualItem } from '@tanstack/react-virtual';
import { List } from 'antd';
import NotificationItem from '@/pages/Notifications/NotificationItem';
import useNotifications from '@/pages/Notifications/hooks/useNotificationsMutation';
import { ReactComponent as ImgNoNotificationsData } from '@/assets/images/list/no-notifications-data.svg';
import styled from '@emotion/styled';
import theme from '@/assets/styles/theme';
import Loading from '@/components/loading/Loading';

function NotificationHistory() {
  const { data, isFetchingNextPage, fetchNextPage, hasNextPage, isFetching } =
    useNotificationQuery();
  const { mutate } = useNotifications();

  const parentRef = useRef<HTMLDivElement>(null);

  const allRows = data ? data.pages.flatMap((d: { rows: any }) => d.rows) : [];

  const rowVirtualizer = useVirtualizer({
    count: hasNextPage ? allRows.length + 1 : allRows.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 124,
    overscan: 1,
  });

  useEffect(() => {
    const [lastItem] = [...rowVirtualizer.getVirtualItems()].reverse();

    if (!lastItem) {
      return;
    }

    if (lastItem.index >= allRows.length - 1 && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [
    hasNextPage,
    fetchNextPage,
    allRows.length,
    isFetchingNextPage,
    rowVirtualizer,
    rowVirtualizer.getVirtualItems(),
  ]);

  const onItemClick = useCallback(
    (id: number) => {
      mutate(id);
    },
    [mutate],
  );

  const customizeRenderEmpty = () => (
    <NoNotificationData>
      <ImgNoNotificationsData />
      <span>알림이 없습니다.</span>
    </NoNotificationData>
  );

  if (isFetching && !isFetchingNextPage) {
    return (
      <div style={{ height: 366 }}>
        <Loading />
      </div>
    );
  }

  return (
    <>
      <div
        ref={parentRef}
        style={{
          overflow: 'auto',
          height: 366,
        }}
      >
        {allRows.length === 0 ? (
          customizeRenderEmpty()
        ) : (
          <List
            dataSource={rowVirtualizer.getVirtualItems()}
            renderItem={(virtualRow: VirtualItem<any>) => (
              <NotificationItem
                virtualRow={virtualRow}
                allRows={allRows}
                hasNextPage={hasNextPage}
                onClick={onItemClick}
              />
            )}
          />
        )}
      </div>
    </>
  );
}

export default NotificationHistory;

const NoNotificationData = styled.div`
  font-size: 13px;
  color: ${theme.color.gray.gray400};

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  span {
    margin-top: 20px;
  }
`;
