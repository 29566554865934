import { atom } from 'recoil';

export const mobileSizeState = atom<boolean>({
  key: 'mobileSizeState',
  default: window.innerWidth < 768,
});

export const size768HigherState = atom<boolean>({
  key: 'size768HigherState',
  default: window.innerWidth >= 768 && window.innerWidth < 1024,
});
