import React, { useState } from 'react';
import styled from '@emotion/styled';
import { ReactComponent as IconPlus } from '@/assets/images/button/icon-plus.svg';
import Popover from '@/components/antd/Popover';
import theme from '@/assets/styles/theme';
import CreateSubtitleContents from '@/components/layout/aside/CreateNewSubtitle/CreateSubtitleContents';
import { useNavigate } from 'react-router-dom';
import FileUploadModal from '@/pages/Notes/FileUploadModal';
import InducePaymentModal from '@/components/popup/InducePaymentModal';
import { useRecoilState } from 'recoil';
import { organizationState } from '@/atoms/organizationState';
import Modal from '@/components/popup/Modal';

interface CreateNewSubtitleProps {
  mobileSize?: boolean;
  webCaption: boolean;
  noteYoutube: boolean;
  fileUpload: boolean;
}

function CreateNewSubtitle({
  mobileSize,
  webCaption,
  noteYoutube,
  fileUpload,
}: CreateNewSubtitleProps) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);

  const [inducePaymentOpen, setInducePaymentOpen] = useState(false);
  const [fileUploadModal, setFileUploadModal] = useState(false);

  const [organizationInfo] = useRecoilState(organizationState);

  const onOpenChange = (open: boolean) => {
    if (!organizationInfo.isActive && organizationInfo.isHome) {
      setInducePaymentOpen(true);
      return;
    }

    if (!organizationInfo.isActive) {
      setAlertOpen(true);
      return;
    }

    setOpen(open);
  };

  const onFileUploadModalCancel = () => {
    setFileUploadModal(false);
  };

  const onYoutubeClick = () => {
    setOpen(false);
    navigate('/upload');
  };

  const onFileClick = () => {
    setOpen(false);
    navigate('/notes?sort_key=id');
    setFileUploadModal(true);
  };

  const onWebCaptionClick = () => {
    setOpen(false);
    window.open('/recognition', '_blank');
  };

  const onInducePaymentSubscribe = (productId: string) => {
    navigate('/subscriptions', { state: { inducePayment: true, productId } });
    setInducePaymentOpen(false);
  };

  const onInducePaymentCancel = () => {
    setInducePaymentOpen(false);
  };

  const onOk = () => {
    setAlertOpen(false);
  };

  return (
    <>
      <CreateNewSubtitlePopover
        open={open}
        onOpenChange={onOpenChange}
        placement={mobileSize ? 'bottom' : 'rightTop'}
        content={
          <CreateSubtitleContents
            noteYoutube={noteYoutube}
            webCaption={webCaption}
            fileUpload={fileUpload}
            onYoutubeClick={onYoutubeClick}
            onFileClick={onFileClick}
            onWebCaptionClick={onWebCaptionClick}
          />
        }
        arrow={false}
        trigger="click"
      >
        <StyledCreateNewSubtitle>
          <IconPlus />
          <span>새 자막 시작</span>
        </StyledCreateNewSubtitle>
      </CreateNewSubtitlePopover>
      {alertOpen && (
        <Modal
          open={alertOpen}
          onOk={onOk}
          okText="확인"
          title="활성화 워크스페이스에서만 이용 가능합니다."
        />
      )}
      {fileUploadModal && <FileUploadModal onCancel={onFileUploadModalCancel} />}
      <InducePaymentModal
        open={inducePaymentOpen}
        onSubscribe={onInducePaymentSubscribe}
        onCancel={onInducePaymentCancel}
      />
    </>
  );
}

export default CreateNewSubtitle;

const CreateNewSubtitlePopover = styled(Popover)`
  .ant-popover-inner {
    box-sizing: border-box;
    padding: 24px 0;

    background: ${(props) => props.theme.color.white};

    border: 1px solid ${(props) => props.theme.color.gray.gray300};
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
  }
`;

const StyledCreateNewSubtitle = styled.div`
  margin: 0 1.6rem;
  border-radius: 4px;

  background-color: ${theme.color.primary.default};
  height: 40px;

  display: flex;
  align-items: center;
  justify-content: left;
  gap: 15px;
  padding: 8px 16px;
  color: ${theme.color.white.default};

  font-size: 16px;
  font-weight: 700;

  :hover {
    cursor: pointer;
  }
`;
