import React, { useCallback } from 'react';
import SEOMetaTag from '@/components/SEOMetaTag';
import AppBodyLayout from '@/components/app/AppBodyLayout';
import PageHeader from '@/components/layout/PageHeader';
import Register from '@/components/register/Register';
import InputText from '@/components/inputText/InputText';
import { inviteCodeDescription } from '@/lib/util/text';
import useMemberCheck from '@/hooks/useMemberCheck';
import { Form, Typography } from 'antd';
import useUserInviteCodeMutation from '@/pages/Account/hooks/useUserInviteCodeMutation';
import { useRecoilState } from 'recoil';
import { inviteCodeErrorState } from '@/pages/Account/atoms/inviteCodeErrorState';
import Modal from '@/components/popup/Modal';
import { inviteCodeJoinSuccessState } from '@/pages/Account/atoms/invitecodeJoinSuccessState';
import { removeSessionSerializedData } from '@/lib/util/storage';
import { useNavigate } from 'react-router-dom';

const { Text } = Typography;

export const errorText = (state: number) => {
  switch (state) {
    case 42211:
      return (
        <span>
          는<Text type="danger"> 이미 가입된 워크스페이스입니다. </Text>
        </span>
      );
    case 42213:
      return (
        <span>
          는<Text type="danger"> 이미 등록된 초대코드입니다.</Text>
        </span>
      );
    case 42214:
      return (
        <span>
          와<Text type="danger"> 일치하는 초대코드가 없습니다. </Text>
        </span>
      );
    default:
      return;
  }
};

function UserInviteCode() {
  useMemberCheck();

  const navigate = useNavigate();
  const [form] = Form.useForm();

  const { mutateAsync } = useUserInviteCodeMutation();
  const [inviteCodeError, setInviteCodeError] = useRecoilState(inviteCodeErrorState);
  const [inviteCodeSuccess, setInviteCodeSuccess] = useRecoilState(inviteCodeJoinSuccessState);

  const onFinish = useCallback(
    async (values: any) => {
      const { inviteCode } = values;

      await mutateAsync(inviteCode);
      await form.resetFields();
    },
    [mutateAsync],
  );

  const onCloseModal = useCallback(() => {
    setInviteCodeError(0);
  }, [setInviteCodeError]);

  const onSuccessModal = useCallback(async () => {
    await navigate('/account?select-workspace=true');
    setInviteCodeSuccess(false);
  }, [setInviteCodeSuccess]);

  const onCloseSuccessModal = useCallback(async () => {
    await removeSessionSerializedData('organization');
    await setInviteCodeSuccess(false);
    await form.resetFields();
  }, [setInviteCodeSuccess, form]);

  return (
    <>
      <SEOMetaTag title="초대코드 회원가입" />
      <AppBodyLayout
        pageHeader={
          <PageHeader
            header={<h1 className="title text-ellipsis overflow-hidden">초대코드 입력</h1>}
          />
        }
      >
        <Register
          descriptions={inviteCodeDescription}
          name="inviteCode"
          form={form}
          onFinish={onFinish}
        >
          <InputText autoComplete="off" placeholder="초대코드를 입력해 주세요." />
        </Register>

        {inviteCodeError !== 0 && (
          <Modal title="초대코드를 등록할 수 없습니다." onOk={onCloseModal} okText="확인">
            입력하신 초대코드{errorText(inviteCodeError)} 초대코드를 확인해 주세요.
          </Modal>
        )}

        {inviteCodeSuccess && (
          <Modal
            title="초대코드가 등록되었습니다."
            onOk={onCloseSuccessModal}
            okText="확인"
            onCancel={onSuccessModal}
            cancelText="워크스페이스 전환"
          >
            '워크스페이스 전환' 버튼을 누르시면 추가하신 워크스페이스를 선택하여 소보로를 이용할 수
            있습니다.
          </Modal>
        )}
      </AppBodyLayout>
    </>
  );
}

export default UserInviteCode;
