import useOptimisticMutation from '@/hooks/useOptimisticMutation';
import { queryKey } from './useNotesQuery';
import apiClient from '@/lib/api/apiClient';
import { organizationState } from '@/atoms/organizationState';
import { useRecoilState } from 'recoil';

export interface IUploadData {
  title: string;
  duration: number;
  language: 'ko-KR' | 'en-US' | 'enko';
  size: number;
  origin_name: string;
  file_uid: string;
}

export default function useUploadMutation() {
  const [organizationInfo] = useRecoilState(organizationState);

  const uploadFile = async ({ value }: { value: IUploadData }) => {
    const { data } = await apiClient.post(`/file-notes`, value, {
      headers: {
        ['x-organization']: organizationInfo.id,
      },
    });

    return data;
  };

  return useOptimisticMutation(queryKey, uploadFile, '정상적으로 등록 되었습니다.');
}
