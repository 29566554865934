import { useQuery } from '@tanstack/react-query';
import apiClient from '@/lib/api/apiClient';

export interface IDeviceData {
  uid: number;
  ssaid: string;
  details: any;
  loggedAt: Date;
  createdAt: Date;
  updatedAt: Date;
}

export const queryKey = ['devices'];

export const useDevicesQuery = () => {
  const { data, isLoading, error } = useQuery(
    [...queryKey],
    async () => {
      return (await apiClient.get('/device-ssaids')) as { data: IDeviceData[] };
    },
    {
      retry: false,
      cacheTime: 0,
      refetchOnWindowFocus: true,
    },
  );

  return { data: data ? data.data : [], isLoading, error };
};
