import React from 'react';
import styled from '@emotion/styled';
import { Divider } from 'antd';
import { ReactComponent as IconCalendar } from '@/assets/images/icon/icon-calendar-gray.svg';
import theme from '@/assets/styles/theme';
import { Link } from 'react-router-dom';

interface OrganizationPlanCardProps {
  planName: string;
  period: string;
  isSignupPromotionProduct?: boolean;
  productBasicDuration?: number;
  openWorkflow: () => void;
  subscriptionType?: string;
}

function OrganizationPlanCard({
  planName,
  period,
  isSignupPromotionProduct,
  productBasicDuration,
  openWorkflow,
  subscriptionType,
}: OrganizationPlanCardProps) {
  return (
    <StyledOrganizationPlanCard>
      <OrganizationPlanCardTitle>MY 플랜</OrganizationPlanCardTitle>
      {period !== '' ? (
        <>
          <OrganizationPlanCardDivider />
          <OrganizationPlanCardPeriod>
            <span>{planName}</span>
            <IconCalendar fill={theme.color.gray.gray700} />
            <span>{period}</span>
          </OrganizationPlanCardPeriod>
        </>
      ) : (
        <>
          <OrganizationPlanCardDivider />
          <OrganizationPlanCardPeriod>
            <span>없음</span>
          </OrganizationPlanCardPeriod>
        </>
      )}
      {subscriptionType !== 'google_play' && (
        <>
          {!isSignupPromotionProduct && !productBasicDuration && (
            <OrganizationPlanPlanPayment to="/subscriptions">
              최대 90,000원 할인 받기
            </OrganizationPlanPlanPayment>
          )}
          {isSignupPromotionProduct && (
            <OrganizationPlanPlanPayment to="/subscriptions">
              최대 90,000원 할인 받기
            </OrganizationPlanPlanPayment>
          )}
          {!isSignupPromotionProduct && productBasicDuration === 1 && (
            <OrganizationPlanCardButton onClick={openWorkflow} className="channel-service">
              29,800원 더 할인 받기
            </OrganizationPlanCardButton>
          )}
        </>
      )}
    </StyledOrganizationPlanCard>
  );
}

export default OrganizationPlanCard;

const StyledOrganizationPlanCard = styled.div`
  padding: 20px 16px;
  margin-bottom: 1.5rem;

  background: ${theme.color.menu.backB};
`;

const OrganizationPlanCardTitle = styled.section`
  padding-bottom: 10px;
  border-bottom: 1px solid #cbd6fe;
  font-weight: 700;
`;

const OrganizationPlanCardDivider = styled(Divider)`
  background-color: ${(props) => props.theme.color.white.default};
  margin: 8px 0;
`;

const OrganizationPlanCardPeriod = styled.section`
  color: ${(props) => props.theme.color.gray.gray700};
  font-size: 13px;

  display: grid;
  grid-template-columns: 20px auto;
  align-items: center;

  span:first-of-type {
    grid-column: 1/ 3;
    margin-bottom: 8px;

    font-weight: 700;
    color: ${theme.color.primary.default};
    font-size: 13px;
  }
`;

const OrganizationPlanCardButton = styled.section`
  margin-top: 16px;

  border-radius: 4px;
  background: ${(props) => props.theme.color.primary.default};

  color: ${(props) => props.theme.color.white.default};
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  cursor: pointer;

  padding: 8px 0;

  text-align: center;
`;

const OrganizationPlanPlanPayment = styled(Link)`
  span {
  }
  margin-top: 16px;

  border-radius: 4px;
  background: ${(props) => props.theme.color.primary.default};
  display: inline-block;
  width: 100%;

  color: ${(props) => props.theme.color.white.default};
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  cursor: pointer;

  padding: 8px 0;

  text-align: center;
`;
