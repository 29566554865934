import { atom } from 'recoil';

interface ICreditState {
  id: number;
  startAt: string;
  endAt: string;
  productName: string;
  productBasicDuration: number;
  isSignupPromotionProduct: boolean;
}

interface ISubscriptionState {
  createdAt: string;
  description: string;
  platform: string;
  subscribedAt: string;
  type: string;
  unsubscribedAt: string | null;
  updatedAt: string;
  willRenewalAt: string;
  periodOfUse: {
    startAt: string;
    endAt: string;
  };
}

export interface IOrganizationState {
  id: number;
  createdAt: string;
  updatedAt: string;
  displayName: string;
  isActive: boolean;
  isManage: boolean;
  isHome: boolean;
  functionGroup?: any;
  credit?: ICreditState;
  subscription?: ISubscriptionState;
}

export const organizationState = atom<IOrganizationState>({
  key: 'organizationState',
  default: {
    id: 0,
    createdAt: '',
    updatedAt: '',
    displayName: '',
    isActive: false,
    isManage: false,
    isHome: false,
  },
});
