import { PasswordProps } from 'antd/lib/input/Password';
import { Input } from 'antd';
import React, { forwardRef, useState } from 'react';
import { StyledTextInput } from '@/components/inputText/styleOfInput';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import theme from '@/assets/styles/theme';

interface TInternalProps extends Omit<PasswordProps, 'allowClear'> {
  isError?: boolean;
  visibleIcon?: boolean;
}

const InputPassword = forwardRef<any, TInternalProps>((props, ref) => {
  const {
    isError,
    visibleIcon = false,
    visibilityToggle = true,
    className,
    placeholder,
    showCount,
    maxLength,
    ...rest
  } = props;

  const [passwordVisible, setPasswordVisible] = useState(false);

  let { status } = props;

  if (isError) {
    status = 'error';
  }

  return (
    <>
      <StyledPasswordInput
        ref={ref}
        placeholder={placeholder}
        showCount={showCount}
        maxLength={maxLength}
        className={`${className || ''}`}
        visibilityToggle={
          visibilityToggle && { visible: passwordVisible, onVisibleChange: setPasswordVisible }
        }
        {...rest}
        iconRender={(visible) => visibleToggleIcon(visible, visibleIcon)}
        status={status}
      />
    </>
  );
});
export default InputPassword;

const StyledPasswordInput = StyledTextInput.withComponent(Input.Password);

const visibleToggleIcon = (visible: boolean, visibleIcon: boolean) => {
  return visible ? (
    <EyeOutlined
      style={{
        color: theme.color.gray.gray300,
        fontSize: '1.6rem',
        visibility: visibleIcon ? 'visible' : 'hidden',
      }}
    />
  ) : (
    <EyeInvisibleOutlined
      style={{
        color: theme.color.gray.gray300,
        fontSize: '1.6rem',
        visibility: visibleIcon ? 'visible' : 'hidden',
      }}
    />
  );
};
