/**
 * Set Serialized Data
 */
export const setSerializedData = (key: string, data: any) => {
  window.localStorage.setItem(key, JSON.stringify(data));
};

/**
 * Get Serialized Data
 */
export const getSerializedData = (key: string, defaultData?: any | null) => {
  try {
    if (!defaultData) defaultData = null;

    const dataString = window.localStorage.getItem(key);
    if (!dataString) {
      return defaultData;
    }

    return JSON.parse(dataString);
  } catch (error) {
    return defaultData;
  }
};

/**
 * Delete Serialized Data
 */
export const removeSerializedData = (key: string) => {
  return window.localStorage.removeItem(key);
};

/**
 * Set SessionSerialized Data
 */
export const setSessionSerializedData = (key: string, data: any) => {
  window.sessionStorage.setItem(key, JSON.stringify(data));
};

/**
 * Get SessionSerialized Data
 */
export const getSessionSerializedData = (key: string) => {
  const dataString = window.sessionStorage.getItem(key);
  if (!dataString) {
    return null;
  }

  return JSON.parse(dataString);
};

/**
 * Delete SessionSerialized Data
 */
export const removeSessionSerializedData = (key: string) => {
  return window.sessionStorage.removeItem(key);
};
