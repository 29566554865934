import { useRecoilState } from 'recoil';
import { organizationState } from '@/atoms/organizationState';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

export default function useMemberCheck() {
  const [organizationInfo] = useRecoilState(organizationState);
  const { isManage } = organizationInfo;

  const navigate = useNavigate();

  useEffect(() => {
    if (isManage) {
      navigate('/', { replace: true });
    }
  }, [isManage, navigate]);
}
