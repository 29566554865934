import { useQuery } from '@tanstack/react-query';
import apiClient from '@/lib/api/apiClient';

export interface IFunctionGroupData {
  name: string;
  tags: string;
  description: string;
  details: any;
}

export interface ICreditData {
  id: number;
  startAt: string;
  endAt: string;
}

export interface ISubscriptionData {
  periodOfUse: {
    startAt: string;
    endAt: string;
  };
}

export interface IOrganizationData {
  id: number;
  displayName: string;
  createdAt: string;
  updatedAt: string;
  isManage: boolean;
  isActive: boolean;
  functionGroup?: IFunctionGroupData;
  credit?: ICreditData;
  subscription?: ISubscriptionData;
}

export const queryKey = ['organizations'];

export const useOrganizationsQuery = () => {
  const { data, isLoading, error } = useQuery(
    [...queryKey],
    async () => {
      return (await apiClient.get('organizations')) as {
        data: IOrganizationData[];
      };
    },
    {
      retry: false,
      refetchOnMount: true,
      cacheTime: 0,
    },
  );

  return { data: data ? data.data : [], isLoading, error };
};
