import React, { useCallback, useState } from 'react';
import { Form } from 'antd';
import Modal from '@/components/popup/Modal';
import messageInForm from '@/assets/messageForm';
import InputPassword from '@/components/inputPassword/InputPassword';

interface Values {
  title: string;
  description: string;
  modifier: string;
}

interface TModalToCheckPasswordProps {
  visible: boolean;
  onOk: (values: Values) => void;
  onCancel: () => void;
  loading?: boolean;
}

const ModalToCheckPassword: React.FC<TModalToCheckPasswordProps> = ({
  visible,
  onOk,
  onCancel,
  loading,
}) => {
  const [form] = Form.useForm();
  const password = Form.useWatch('password', form);

  const [buttonDisabled, setButtonDisabled] = useState(true);

  const handleOkModal = useCallback(() => {
    form
      .validateFields()
      .then((values) => {
        form.resetFields();
        onOk(values);
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  }, [form, onOk]);

  const handleCancelModal = useCallback(() => {
    onCancel();
    form.resetFields();
  }, [onCancel, form]);

  const validator = useCallback((_: any, value: string) => {
    if (value.length > 0) {
      setButtonDisabled(false);
      return Promise.resolve();
    }

    setButtonDisabled(true);
    return Promise.reject();
  }, []);

  return (
    <Modal
      open={visible}
      data-visible={visible}
      title="비밀번호 확인"
      okText="확인"
      cancelText="취소"
      closable={false}
      onCancel={handleCancelModal}
      onOk={handleOkModal}
      loading={loading}
      isButtonDisabled={buttonDisabled}
    >
      <p>개인정보를 안전하게 보호하기 위해 비밀번호를 다시 한 번 확인합니다.</p>
      <Form
        form={form}
        data-testid="checkPassword"
        name="formInModal"
        layout="vertical"
        requiredMark={false}
        autoComplete="off"
      >
        <Form.Item
          name="password"
          rules={[
            { required: true, message: messageInForm.password.error },
            {
              validator: validator,
            },
          ]}
          className="mt-[3.3rem]"
        >
          <InputPassword
            visibleIcon={password && password.length > 0}
            visibilityToggle
            placeholder={messageInForm.password.placeholder}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalToCheckPassword;
