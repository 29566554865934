export async function checkFileDuration(file: File | Blob, type: 'audio' | 'video') {
  return new Promise<number>((resolve) => {
    const element = document.createElement(type);
    element.src = URL.createObjectURL(file);

    element.addEventListener('loadedmetadata', () => {
      const duration = element.duration;
      resolve(duration);
    });
  });
}
