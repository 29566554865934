import { useQuery } from '@tanstack/react-query';
import apiClient from '@/lib/api/apiClient';

export const queryKey = ['notifications-setting'];

export interface INotificationSettingData {
  settings: {
    system: boolean;
    notice: boolean;
    marketing: boolean;
  };
  platform: 'web' | 'app';
  createdAt: string;
  updatedAt: string;
}

export const useNotificationsSettingQuery = () => {
  const { data, isLoading, error } = useQuery(
    [...queryKey],
    async () => {
      return (await apiClient.get(
        `notification-settings`,
        {},
        {
          headers: { 'x-platform': 'web' },
        },
      )) as { data: INotificationSettingData };
    },
    {
      retry: false,
      refetchOnMount: true,
      cacheTime: 0,
    },
  );

  return { data: data ? data.data : null, isLoading, error };
};
