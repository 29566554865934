import { css } from '@emotion/react';
import Button from '@/components/button/Button';
import { ReactComponent as IconCloseAside } from '@/assets/images/button/icon-close-aside.svg';
import theme from '@/assets/styles/theme';
import React from 'react';

/* 닫는 버튼 */
interface TInternalProps {
  className: string;
  onClick: () => void;
}

const ButtonToCloseAside = (props: TInternalProps, ref: any) => {
  const { className, onClick } = props;

  const styledCss = css`
    width: 2.4rem;
    height: 2.4rem;
    padding: 0;
    border-radius: 0.4rem;

    svg {
      margin: 0 auto;
    }

    &:hover {
      background-color: #e7edf5;

      svg {
        fill: #b2becf;
      }
    }
  `;

  return (
    <Button design="tertiary" onClick={onClick} className={className} css={styledCss} ref={ref}>
      <IconCloseAside fill={theme.color.line} />
    </Button>
  );
};

export default React.forwardRef(ButtonToCloseAside);
