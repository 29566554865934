import apiClient from '@/lib/api/apiClient';
import { useMutation } from '@tanstack/react-query';
import { messageInfo } from '@/components/popup/message';
import { removeSessionSerializedData } from '@/lib/util/storage';
import { useNavigate } from 'react-router-dom';
import { auth } from '@/lib/auth/firebase-auth';

interface IWithdrawParam {
  reason: string[];
  content: string | undefined;
}

export default function useWithdrawMutation() {
  const navigate = useNavigate();

  const withdraw = async ({ reason, content }: IWithdrawParam) => {
    const withdrawReason = reason ? reason : [];

    try {
      const { data } = await apiClient.put(`/withdraw`, {
        reason: withdrawReason,
        ...(content && { content }),
      });
      return data;
    } catch (e) {
      throw e;
    }
  };

  return useMutation(withdraw, {
    onSuccess: async () => {
      messageInfo({
        content: '소보로를 탈퇴했습니다.',
        noIcon: true,
      });
      await auth.signOut();
      await removeSessionSerializedData('organization');
      await navigate('/sign-in', { replace: true });
    },
    onError: async () => {},
  });
}
