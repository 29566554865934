import useNotificationInfiniteQuery, {
  INotificationHistoryData,
} from '@/pages/Notifications/hooks/useNotificationsInfiniteQuery';
import { SortDirection } from '@/hooks/useNotesInfiniteQuery';

interface INotificationsGroup {
  rows: INotificationHistoryData[];
  total: number;
}

const defaultFilter = {
  page: 1,
  perPage: 5,
  sortKey: 'id',
  sortDirection: SortDirection.DESC,
};

export const queryKey = ['notification-histories'];

const select = (recentNotificationsData: any) => {
  const pages = recentNotificationsData.pages.map(
    (recentNotificationGroup: INotificationsGroup) => {
      const rows = recentNotificationGroup.rows.map((notification) => {
        return {
          ...notification,
        };
      });

      return {
        ...recentNotificationGroup,
        rows,
      };
    },
  );

  return {
    ...recentNotificationsData,
    pages,
  };
};

export const useNotificationQuery = () => {
  return useNotificationInfiniteQuery(queryKey, select, defaultFilter);
};
