import AntdModal, { ModalProps } from 'antd/lib/modal/Modal';
import * as React from 'react';
import { useMemo } from 'react';
import styled from '@emotion/styled';
import Button from '@/components/button/Button';
import Loading from '@/components/loading/Loading';
import { ReactComponent as IconClose } from '@/assets/images/button/icon-close.svg';
import theme from '@/assets/styles/theme';

interface TModalProps extends ModalProps {
  loading?: boolean;
  isButtonDisabled?: boolean;
  type?: 'primary' | 'secondary' | 'title' | 'tertiary' | 'left';
  onOk?: () => void;
  onCancel?: () => void;
}

/**
 * - `onCancel` 이 있어야 `maskClosable=true` 가 적용되어, 마스크(모달 외부 영역)를 클릭시 모달 상자가 닫힘.
 * */
const Modal = (props: TModalProps) => {
  const {
    children,
    className,
    title,
    visible = true,
    loading,
    width,
    okText,
    cancelText,
    onOk,
    onCancel,
    closable,
    type = 'primary',
    isButtonDisabled,
    ...rest
  } = props;

  const OkButton = useMemo(() => {
    if (!okText) {
      return null;
    }

    return (
      <Button
        design="modal1"
        loading={loading}
        onClick={onOk}
        key="onOk"
        disabled={isButtonDisabled}
        className={className}
      >
        {okText}
      </Button>
    );
  }, [okText, loading, onOk, isButtonDisabled]);

  const CancelButton = useMemo(() => {
    if (!cancelText) {
      return null;
    }
    return (
      <Button design="modal2" onClick={onCancel} key="onCancel" className={className}>
        {cancelText}
      </Button>
    );
  }, [cancelText, onCancel]);

  return (
    <StyledModal
      type={type}
      title={title}
      open={visible}
      centered={true}
      width={width || '320px'}
      closeIcon={<IconClose />}
      closable={!!closable}
      onOk={onOk}
      onCancel={onCancel}
      footer={[CancelButton || null, OkButton]}
      className={`${className || ''}`}
      transitionName="none"
      maskTransitionName="none"
      {...rest}
    >
      {loading && <Loading layer="absolute" tip="" />}
      {children}
    </StyledModal>
  );
};

export default Modal;

const StyledModal = styled(AntdModal)((props: { type: TModalProps['type'] }) => {
  const { type } = props;

  let headerPaddingTop = '3.2rem';
  let modalBodyPaddingBottom = '3.2rem';
  let textAlign = 'left';
  let modalPaddingLeftRight = '2rem';
  let footerDisplay = 'display: flex; justify-content: space-between;';

  let antModalTitle = `
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 128.9%;
  `;

  if (type === 'secondary') {
    headerPaddingTop = '2rem';
    modalBodyPaddingBottom = '2rem';
    textAlign = 'left';
  }

  if (type === 'title') {
    modalBodyPaddingBottom = '1.2rem';
  }

  if (type === 'tertiary') {
    modalPaddingLeftRight = '4rem';
    antModalTitle = `
      font-size: 2.4rem;
      font-weight: 700;
      line-height: 36px;
    `;
  }

  if (type === 'left') {
    antModalTitle = `
      font-size: 1.6rem;
      font-weight: 700;
      line-height: 128.9%;
      text-align: left;
    `;
  }

  return `
  
    &.unsubscribe .ant-modal-body {
      margin-top: 1.6rem;
      border-top: 1px solid ${theme.color.gray.gray200};
    }
  
    &.ant-modal {
      margin: 1rem auto;
    }
    
    .ant-modal-close {
      top: ${headerPaddingTop};
      svg path {
        fill: ${theme.color.gray.gray300} !important;
      }
      
      &:hover {
        background: none;
      }
    }
    
    
    .ant-modal-content {
      border-radius: 6px;
      padding: 0;
    }
    
    .ant-modal-header {
      margin-bottom: 0;
      padding: ${headerPaddingTop} ${modalPaddingLeftRight} 0;
      border: none;
      border-radius: 6px 6px 0 0;
      text-align: center;
    }
    
    .ant-modal-title {
      ${antModalTitle}
    }
    
    .ant-modal-body {
      padding: 2.4rem ${modalPaddingLeftRight} ${modalBodyPaddingBottom};
      font-weight: 400;
      text-align: ${textAlign}
      font-size: 12px;
      color: ${theme.color.gray.gray700}
      
    }
    
    .ant-modal-footer {
       ${type === 'tertiary' ? footerDisplay : ''}
    
       margin-top: 0;
       padding: 0 ${modalPaddingLeftRight} 2rem;
       border: none;
       border-radius: 0 0 6px 6px;
       text-align: center;
       
       button {
        width: calc(50% - 1rem);
        
          &:only-child {
            width: 100%;
          }
       }
       
       button + button {
          margin-left: 2rem;
       }
    }
  `;
});
