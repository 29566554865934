import LayoutAside from '@/components/layout/LayoutAside';
import LayoutBodyAside from '@/components/layout/LayoutBodyAside';
import React, { Suspense, useEffect } from 'react';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import { Outlet, useLocation, useMatch, useNavigate } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { hideNavState } from '@/atoms/navState';
import { authState } from '@/atoms/authState';
import Loading from '@/components/loading/Loading';
import { styledLayoutWrap } from '@/components/layout/styleOfLayout';
import { mobileSizeState, size768HigherState } from '@/atoms/mobileSizeState';
import '@/lib/auth/firebase-auth';
import { isMobileState } from '@/atoms/isMobileState';
import apiClient from '@/lib/api/apiClient';
import { deviceTokenState } from '@/atoms/deviceTokenState';
import {
  getSessionSerializedData,
  removeSerializedData,
  removeSessionSerializedData,
} from '@/lib/util/storage';
import { auth, getMessagingToken } from '@/lib/auth/firebase-auth';
import useLogoutMutation from '@/hooks/useLogoutMutation';
import { organizationState } from '@/atoms/organizationState';
import useOnMessage from '@/hooks/useOnMessage';
import Modal from '@/components/popup/Modal';
import { logoutModalState } from '@/atoms/logoutModalState';
import { inviteCodeJoinSuccessState } from '@/pages/Account/atoms/invitecodeJoinSuccessState';

export interface TAppLayoutProps extends React.HTMLAttributes<HTMLDivElement> {}

function AppLayout(props: TAppLayoutProps) {
  const organization = getSessionSerializedData('organization');

  const [logoutModalOpen, setLogoutModalOpen] = useRecoilState(logoutModalState);

  const [mobileSize, setMobileSize] = useRecoilState(mobileSizeState);
  const setSize768Higher = useSetRecoilState(size768HigherState);
  const [isMobile] = useRecoilState(isMobileState);
  const [hideNav, setHideNav] = useRecoilState(hideNavState);
  const [deviceToken, setDeviceToken] = useRecoilState(deviceTokenState);

  const [authInfo] = useRecoilState(authState);
  const [organizationInfo, setOrganization] = useRecoilState(organizationState);
  const [inviteCodeSuccess] = useRecoilState(inviteCodeJoinSuccessState);

  const { isAuthStateLoaded, isLogged } = authInfo;

  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  const search = location.search;
  const state = location.state as any;

  const matchRoot = useMatch('/');
  const matchRecognition = useMatch('/recognition');

  const { children, className, ...rest } = props;

  useOnMessage();

  useEffect(() => {
    if (!organization && isLogged) {
      navigate('/organizations');
    }
  }, [organization, isLogged, navigate, inviteCodeSuccess]);

  useEffect(() => {
    const getInitialState = async () => {
      if (!organization || organizationInfo.id === organization.id || !organization) {
        return;
      }

      if (isLogged && organization.id) {
        try {
          const { data } = await apiClient.get(`organizations/${organization.id}`);
          setOrganization(data);
        } catch (e) {
          removeSerializedData('organization-persist');
          removeSessionSerializedData('organization');
          navigate('/sign-in', { replace: true });
        }
      }
    };
    getInitialState();
  }, [isLogged, organization, setOrganization]); // eslint-disable-line react-hooks/exhaustive-deps

  const { mutate: logoutMutation } = useLogoutMutation();

  const signOut = async () => {
    deviceToken && logoutMutation(deviceToken);
    await auth.signOut();
    navigate('/sign-in', { replace: true });
    setOrganization({
      id: 0,
      createdAt: '',
      updatedAt: '',
      displayName: '',
      isActive: false,
      isManage: false,
      isHome: false,
    });
    organization && removeSessionSerializedData('organization');
  };

  useEffect(() => {
    if (isLogged && !isMobile) {
      Notification.requestPermission()
        .then((permission) => {
          if (permission === 'granted') {
            console.log('Notification permission granted.');

            const messagingToken = getMessagingToken();

            messagingToken &&
              messagingToken
                .then(async (currentToken) => {
                  setDeviceToken(currentToken);
                  await apiClient.post(
                    '/devices',
                    {
                      'platform': 'web',
                    },
                    {
                      headers: {
                        'Device-Token': currentToken,
                      },
                    },
                  );

                  apiClient.post(
                    '/user-app-access-logs',
                    {},
                    {
                      headers: {
                        'Device-Token': currentToken,
                        'x-platform': 'web',
                      },
                    },
                  );
                })
                .catch((err) => {
                  if (err.data.code !== 42209) {
                    console.log(err);
                  }
                });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [isLogged, setDeviceToken, isMobile]);

  useEffect(() => {
    if (isAuthStateLoaded && !isLogged) {
      navigate('/sign-in', {
        replace: true,
        state: {
          from: `${pathname}${search ? search : ''}`,
        },
      });
    }

    if (organizationInfo.id === 0) {
      return;
    }

    if (isLogged && matchRoot && !organizationInfo.isManage) {
      if (state && state.from && !state.from.includes('/organizations')) {
        navigate(state.from, { replace: true });
      } else {
        navigate('/notes?sort_key=id', { replace: true });
      }
    }

    if (isLogged && matchRoot && organizationInfo.isManage) {
      if (state && state.from && state.from.includes('/organizations')) {
        navigate(state.from, { replace: true });
      } else {
        navigate('/organizations/users', { replace: true });
      }
    }
  }, [matchRoot, isAuthStateLoaded, isLogged, navigate, organizationInfo, pathname]);

  /** 창크기 변경시 - 모바일 사이즈 확인 **/
  useEffect(() => {
    const handleResize = debounce(() => {
      const itMobileSize = window.innerWidth < 768 || isMobile;
      setMobileSize(itMobileSize);

      const itSize768Higher = window.innerWidth >= 768 && window.innerWidth < 1024;
      setSize768Higher(itSize768Higher);

      setHideNav(itMobileSize);
    }, 100);

    window.addEventListener('resize', handleResize);
    return () => {
      // cleanup
      window.removeEventListener('resize', handleResize);
    };
  }, [setHideNav, setMobileSize, isMobile, setSize768Higher]);

  const clickGnb = () => {
    setHideNav(!hideNav);
  };

  const handleLogoutModal = async () => {
    setLogoutModalOpen(false);

    await signOut();
  };

  if (!authInfo.isAuthStateLoaded || organizationInfo.id === 0) {
    return <Loading />;
  }

  return (
    <div
      className={classNames(`wrap-layout flex ${className || ''}`, {
        'mobile': mobileSize || isMobile,
      })}
      css={styledLayoutWrap}
      {...rest}
    >
      {isLogged && !matchRecognition && (
        <Suspense fallback={<Loading />}>
          <LayoutAside
            isHidden={matchRecognition ? true : hideNav}
            mobileSize={mobileSize || isMobile}
            clickGnb={clickGnb}
            signOut={signOut}
          />
        </Suspense>
      )}

      <Suspense fallback={<Loading />}>
        <Outlet />
      </Suspense>

      {logoutModalOpen && (
        <Modal
          title="로그아웃"
          open={logoutModalOpen}
          okText="로그인"
          onOk={handleLogoutModal}
          closable={false}
          onCancel={handleLogoutModal}
        >
          <p>
            비밀번호가 변경되어 로그아웃되었습니다.
            <br />
            다시 로그인 해주세요.
          </p>
        </Modal>
      )}
    </div>
  );
}

export { LayoutBodyAside as BodyAside };
export default AppLayout;
