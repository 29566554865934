import React from 'react';
import { VirtualItem } from '@tanstack/react-virtual';
import styled from '@emotion/styled';
import theme from '@/assets/styles/theme';
import { ReactComponent as BadgeNew } from '@/assets/images/badge/badge-new.svg';
import { toDateTime } from '@/lib/util/format';
import { useNavigate } from 'react-router-dom';

interface ItemProps {
  virtualRow: VirtualItem<any>;
  allRows: any;
  hasNextPage: boolean | undefined;
  onClick: (id: number) => void;
}

function NotificationItem({ virtualRow, allRows, hasNextPage, onClick }: ItemProps) {
  const navigate = useNavigate();

  const isLoaderRow = virtualRow.index > allRows.length - 1;
  const data = allRows[virtualRow.index];

  if (isLoaderRow && hasNextPage) {
    return <div>Loading more...</div>;
  }

  if (isLoaderRow && !hasNextPage) {
    return <div>Nothing more to load</div>;
  }

  const onItemClick = (id: number) => () => {
    onClick(id);

    if (data.notification.link) {
      const url = new URL(data.notification.link);
      const relativePath = url.pathname + url.search + url.hash;

      navigate(relativePath);
    }
  };

  return (
    <ListItem
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: `${virtualRow.size}px`,
        transform: `translateY(${virtualRow.start}px)`,
        padding: '16px',
        cursor: 'pointer',
      }}
      key={data.id}
      onClick={onItemClick(data.notification.id)}
    >
      <NotificationItemTitle>
        {!data.isRead ? <BadgeNew /> : ''}
        <span>{data.notification.title}</span>
      </NotificationItemTitle>
      <NotificationItemBody title={data.notification.body}>
        {data.notification.body}
      </NotificationItemBody>
      <NotificationCreatedAt>{toDateTime(data.createdAt, 'MM.DD HH:mm')}</NotificationCreatedAt>
    </ListItem>
  );
}

export default NotificationItem;

const ListItem = styled.div`
  border-top: 1px solid ${theme.color.gray.gray100};
`;

const NotificationItemTitle = styled.div`
  display: flex;
  align-items: center;

  span {
    overflow: hidden;
    white-space: normal;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-all;
    width: 90%;
  }

  font-weight: 500;
  color: ${theme.color.gray.gray900};
  > svg {
    margin-right: 0.5rem;
    margin-top: 0.25rem;
  }
`;

const NotificationItemBody = styled.div`
  color: ${theme.color.gray.gray600};

  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-all;
`;

const NotificationCreatedAt = styled.div`
  font-size: 12px;
  color: ${theme.color.gray.gray400};

  position: absolute;
  bottom: 16px;
`;
