import apiClient from '@/lib/api/apiClient';
import { useMutation } from '@tanstack/react-query';
import { useRecoilState } from 'recoil';
import { deviceTokenState } from '@/atoms/deviceTokenState';

export default function useLogoutDevicesMutation() {
  const [deviceToken] = useRecoilState(deviceTokenState);

  const logoutDevices = async () => {
    try {
      const { data } = await apiClient.post(
        `/logout-devices`,
        {},
        {
          headers: {
            'Device-Token': deviceToken,
          },
        },
      );
      return data;
    } catch (e) {
      throw e;
    }
  };

  return useMutation(logoutDevices, {
    onSuccess: async () => {},
    onError: async () => {},
  });
}
