import React from 'react';
import { Progress, Upload } from 'antd';
import { UploadProps } from 'antd/es/upload/interface';
import styled from '@emotion/styled';
import { ReactComponent as ImgUpload } from '@/assets/images/button/icon-upload.svg';
import theme from '@/assets/styles/theme';
import { UploadFile } from 'antd/lib/upload';
import { durationFormat, fileSizeFormat } from '@/lib/util/format';
import { FileInfoType, FileUploadError } from '@/pages/Notes/FileUploadModal';

const { Dragger } = Upload;

export interface TUploadProps extends UploadProps {
  fileList: UploadFile[];
  fileInfo: FileInfoType;
  percent: number;
  error: FileUploadError;
  onLine: boolean;
}

function SovoroUpload(props: TUploadProps) {
  const { fileList, fileInfo, percent, error, onLine } = props;

  const twoColors = {
    '0%': onLine ? '#3462FF' : '#9E9E9E',
    '100%': onLine ? '#3462FF' : '#9E9E9E',
  };

  return (
    <UploadContainer
      className={['', fileList.length > 0 && 'exist-file', error.type && 'error']
        .filter(Boolean)
        .join(' ')}
    >
      <FileInfo className={['', fileList.length > 0 && 'exist-file'].filter(Boolean).join(' ')}>
        <CustomUpload {...props}>
          {fileList.length < 1 && !error.type && (
            <>
              <p className="ant-upload-drag-icon">
                <ImgUpload fill="#CBD6FE" />
              </p>
              <p className="ant-upload-text">파일을 클릭하거나 영역에 끌어오세요</p>
              <p className="ant-upload-hint">
                한 번에 1개 파일만 등록 가능합니다. <br /> aac, wav, mp3, m4a, ac3, flac, ogg, avi,
                mp4, mov, flv, mkv 최대 120분 / 2GB 이하
              </p>
            </>
          )}
          {error.type && (
            <>
              <p className="ant-upload-drag-icon">
                <ImgUpload fill="#FFC7C7" />
              </p>
              <p className="ant-upload-text">{error.title}</p>
              <p className="ant-upload-hint">
                {error.description1} <br />
                {error.description2}
              </p>
            </>
          )}
        </CustomUpload>
        {fileList.length > 0 && fileInfo && (
          <p className="file-info">
            {fileSizeFormat(fileInfo.size, true)} | {durationFormat(fileInfo.duration)}
          </p>
        )}
      </FileInfo>
      {fileList.length > 0 && fileInfo && (
        <FileProgress className={onLine ? '' : 'disconnected'}>
          <span>{percent}%</span>
          <Progress
            style={{
              marginBottom: 0,
              marginInlineEnd: 0,
            }}
            percent={percent}
            showInfo={false}
            strokeColor={twoColors}
            format={(percent) => percent === 100 && '완료'}
          />
        </FileProgress>
      )}
    </UploadContainer>
  );
}

export default SovoroUpload;

const CustomUpload = styled(Dragger)((props: TUploadProps) => {
  const { fileList, error } = props;
  let display = 'table';
  let background = `${theme.color.menu.backB}`;
  let uploadText = `${theme.color.gray.gray900}`;
  let uploadHint = `${theme.color.gray.gray400}`;
  let itemDisplay = 'none';

  if (fileList.length > 0) {
    display = 'none';
    itemDisplay = 'block';
  }

  if (error.type) {
    background = '#FFF4F4';
    uploadText = `${theme.color.error.default}`;
    uploadHint = `${theme.color.error.default}`;
  }

  return `
  .ant-upload {
    display: ${display};
    border-radius: 10px !important;
    background: ${background} !important;
    border: none !important;
  }
  
    width: 100% !important;
  .ant-upload-wrapper {
  }
  
  .ant-upload-list-item-action {
    display: ${itemDisplay} !important;
  }
  
  .ant-upload-list-item-actions button:hover {
    background: none;
  }
  
  .ant-upload-list-item-thumbnail {
    display: none;
  }
  
  .ant-upload-list-item-progress {
    display: none;
  }
  
  .ant-upload-list-item-name {
    margin-bottom: 0 !important;
    display: ${itemDisplay} !important;
  }

  .ant-upload-drag-icon {
    display: flex;
    justify-content: center;
  }

  .ant-upload-text {
    font-size: 12px !important;
    font-weight: 500 !important;
    color: ${uploadText} !important;
  }

  .ant-upload-hint {
    font-size: 12px !important;
    color: ${uploadHint} !important;
  }

  .ant-upload-list-item {
    border: 1px solid transparent !important;
    height: auto !important;
    width: 100% !important;
    padding: 0 !important;
    margin: 0 !important;
  }

`;
});

const UploadContainer = styled.div`
  .file-info {
    font-size: 12px;
    color: ${theme.color.gray.gray400};
    margin-left: 10px;
  }

  background-color: ${theme.color.menu.backB};
  border-radius: 10px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &.exist-file {
    padding: 20px;
  }

  &.error {
    background-color: #fff4f4;
  }
`;

const FileInfo = styled.div`
  &.exist-file {
    background-color: ${theme.color.white.default};
    width: 100%;
    height: 60px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;

    border-radius: 6px;
    border: 1px solid ${theme.color.gray.gray100};
  }
`;

const FileProgress = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-top: 2rem;

  .ant-progress {
    margin-left: 5px;
  }

  .ant-progress-bg {
    border-radius: 5px 0 0 5px !important;
  }

  span {
    font-size: 12px;

    &:first-of-type {
      color: ${theme.color.gray.gray900};
    }

    &:last-of-type {
      color: ${theme.color.gray.gray400};
    }
  }

  &.disconnected {
    span:first-of-type {
      color: ${theme.color.error.default};
    }
  }
`;
