import { css } from '@emotion/react';
import theme from '@/assets/styles/theme';

export const antdStyleMessage = css`
  .ant-message {
    top: auto;
    bottom: 3.2rem;
  }

  .ant-message-notice.custom {
    box-shadow: none;

    .ant-message-notice-content {
      border-radius: 2rem;
      color: #fff;
      background: rgba(14, 15, 15, 0.8);
    }

    &.no-icon {
      span[role='img'] {
        display: none;
      }
    }
  }

  .ant-typography-danger {
    color: ${theme.formColor.error} !important;
  }
`;

export const antdStyleSelect = css`
  :where(.css-dev-only-do-not-override-8vg56j).ant-select-single.ant-select-lg:not(.ant-select-customize-input)
    .ant-select-selector {
    height: 4.8rem !important;
    align-items: center;
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background: none !important;
    font-weight: normal !important;
    color: ${theme.color.primary.default} !important;
  }
  .ant-select-selector {
    border-radius: 2px;
    color: ${theme.color.gray.gray500};
    font-size: 14px;
  }

  .ant-select-dropdown {
    background: #ffffff;
    box-shadow: 0 0 2px rgba(18, 18, 18, 0.12), 0 2px 2px rgba(18, 18, 18, 0.14),
      0 1px 3px rgba(18, 18, 18, 0.2);
    border-radius: 4px;
  }

  .ant-select-selection-item {
    margin-right: 2rem;
  }
`;

export const antdStyleTooltip = css`
  position: relative;
`;

export const antdStyleCommon = css`
  :where(.css-dev-only-do-not-override-1ldxrf1).ant-list .ant-list-item .ant-list-item-meta {
    align-items: center;
  }

  .ant-list-header {
    padding: 0 !important;
  }

  .ant-typography mark {
    background-color: #dee6ff !important;
  }
`;
