import React, { MouseEvent, useCallback, useState } from 'react';
import { ReactComponent as ImgNotificationOn } from '@/assets/images/gnb/icon-notification-on.svg';
import { ReactComponent as ImgNotificationOff } from '@/assets/images/gnb/icon-notificaition-off.svg';
import styled from '@emotion/styled';
import theme from '@/assets/styles/theme';
import Popover from '@/components/antd/Popover';
import NotificationHistory from '@/pages/Notifications/NotificationHistory';
import NotificationSettings from '@/pages/Notifications/NotificationSettings';
import Button from '@/components/button/Button';
import { ReactComponent as ImgNotificationSetting } from '@/assets/images/gnb/icon-setting.svg';
import { ReactComponent as ImgNotificationClose } from '@/assets/images/gnb/icon-close.svg';
import { media } from '@/assets/styles/GlobalStyles';
import { useNotificationHistoryCountQuery } from '@/pages/Notifications/hooks/useNotificationHistoryCountQuery';

function Notifications() {
  const [open, setOpen] = useState(false);

  const [notificationState, setNotificationState] = useState('');

  const { data } = useNotificationHistoryCountQuery();

  const onOpenChange = useCallback(
    (newOpen: boolean) => {
      if (newOpen) {
        setNotificationState('histories'); // 팝오버가 열릴 때만 상태를 'histories'로 변경
      }
      setOpen(newOpen);
    },
    [setOpen, setNotificationState],
  );

  const onSettingClick = useCallback(
    (e: MouseEvent) => {
      // e.preventDefault();
      e.stopPropagation();

      if (notificationState === 'settings') {
        setOpen(false);
      } else {
        setNotificationState('settings');
      }
    },
    [notificationState, setNotificationState, setOpen],
  );

  return (
    <Notification>
      <NotificationPopover
        open={open}
        title={
          <NotificationHistoryTitle>
            <span>{`알림 ${notificationState === 'histories' ? '내역' : '설정'}`}</span>
            <Button
              design="icon"
              icon={
                notificationState === 'histories' ? (
                  <ImgNotificationSetting />
                ) : (
                  <ImgNotificationClose />
                )
              }
              onClick={onSettingClick}
            />
          </NotificationHistoryTitle>
        }
        placement={'topLeft'}
        content={
          notificationState === 'histories' ? <NotificationHistory /> : <NotificationSettings />
        }
        arrow={false}
        trigger="click"
        onOpenChange={onOpenChange}
      >
        {data && data.count > 0 ? <ImgNotificationOn /> : <ImgNotificationOff />}
        <span>알림</span>
      </NotificationPopover>
    </Notification>
  );
}

export default Notifications;

const Notification = styled.div`
  padding: 1rem 2.8rem 2.5rem;

  display: flex;
  justify-content: space-between;

  > span {
    display: flex;
    align-items: center;
    color: ${theme.color.gray.gray900};
    &:hover {
      cursor: pointer;
    }
    svg {
      margin-right: 0.5rem;
    }
  }
`;

const NotificationPopover = styled(Popover)`
  .ant-popover-inner {
    width: 360px;
    height: 420px;
    flex-shrink: 0;

    padding: 0;

    border-radius: 0;

    background: #fff;
    box-shadow: 0 1px 3px 0 rgba(18, 18, 18, 0.2), 0 2px 2px 0 rgba(18, 18, 18, 0.14),
      0 0 2px 0 rgba(18, 18, 18, 0.12);

    .ant-popover-title {
      margin-bottom: 0;
    }

    ${media.mobile} {
      width: 300px;
    }
  }
`;

const NotificationHistoryTitle = styled.div`
  padding: 12px 16px;
  font-weight: 700;
  font-size: 14px;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;
