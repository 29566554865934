import React from 'react';
import { Link } from 'react-router-dom';
import logo from '@/assets/images/logo-sovoro.svg';
import ButtonToCloseGnb from '@/components/layout/aside/ButtonToCloseAside';
import styled from '@emotion/styled';

interface SovoroLogoProps {
  onClick: () => void;
}

function SovoroLogo({ onClick }: SovoroLogoProps) {
  return (
    <StyledSovoroLogo>
      <Link to="/">
        <img src={logo} alt="sovoro 로고" width={102} height={24} />
      </Link>
      <ButtonToCloseGnb className="btn-gnb-close flex-none" onClick={onClick} />
    </StyledSovoroLogo>
  );
}

export default SovoroLogo;

const StyledSovoroLogo = styled.div`
  display: flex;
  justify-content: space-between;

  padding: 0 1.6rem;
  margin-bottom: 3.2rem;
`;
