import React from 'react';
import styled from '@emotion/styled';
import { Divider } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { hideNavState } from '@/atoms/navState';
import { isMobileState } from '@/atoms/isMobileState';
import useFunctionGroupCheck from '@/hooks/useFunctionGroupCheck';

interface AccountInfoMenuProps {
  setPopoverOpen: (open: boolean) => void;
  signOut?: () => void;
}

function AccountInfoMenu({ setPopoverOpen, signOut = () => {} }: AccountInfoMenuProps) {
  const planPayment = useFunctionGroupCheck('planPayment');

  return (
    <StyledAccountInfoMenu>
      <AccountInfoMenuItem to="/account" name="내 계정" setPopoverOpen={setPopoverOpen} />

      {planPayment && (
        <AccountInfoMenuItem
          to="/subscriptions"
          name="플랜 및 결제"
          setPopoverOpen={setPopoverOpen}
        />
      )}

      <AccountInfoMenuItem
        to="/terms-and-conditions"
        name="서비스 약관"
        setPopoverOpen={setPopoverOpen}
      />
      <AccountInfoMenuDivider />
      <AccountInfoLogout onClick={signOut}>로그아웃</AccountInfoLogout>
    </StyledAccountInfoMenu>
  );
}

export default AccountInfoMenu;

const StyledAccountInfoMenu = styled.div`
  display: flex;
  flex-direction: column;
`;

interface AccountInfoMenuItemProps {
  name: string;
  to: string;
  setPopoverOpen: (open: boolean) => void;
}

function AccountInfoMenuItem({ name, to, setPopoverOpen }: AccountInfoMenuItemProps) {
  const navigate = useNavigate();

  const setHideNav = useSetRecoilState(hideNavState);
  const [isMobile] = useRecoilState(isMobileState);

  const onClick = () => {
    setPopoverOpen(false);
    navigate(to);

    if (isMobile) {
      setHideNav(true);
    }
  };

  return <StyledAccountInfoMenuItem onClick={onClick}>{name}</StyledAccountInfoMenuItem>;
}

const StyledAccountInfoMenuItem = styled.li`
  height: 40px;

  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  display: flex;
  align-items: center;

  padding: 8px 24px;

  cursor: pointer;

  :hover {
    background: ${(props) => props.theme.color.menu.backB};
  }
`;

const AccountInfoMenuDivider = styled(Divider)`
  width: 160px;
  min-width: 160px;

  margin: 16px auto;

  border-color: ${(props) => props.theme.color.gray.gray300};
`;

const AccountInfoLogout = styled.li`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  display: flex;
  align-items: center;

  cursor: pointer;

  padding: 0 24px;

  color: ${(props) => props.theme.color.gray.gray400};
`;
