import { useInfiniteQuery } from '@tanstack/react-query';
import apiClient from '@/lib/api/apiClient';
import { NoteRecordingSttStatus } from '@/pages/NoteDetail/hooks/useNoteQuery';
import { useSearchParams } from 'react-router-dom';

export enum SortDirection {
  DESC = 'DESC',
  ASC = 'ASC',
}

interface IDefaultFilter {
  page: number;
  perPage: number;
  sortKey?: string;
  sortDirection: SortDirection;
  type?: string;
}

export interface INotesData {
  id: number;
  title: string;
  summary: string;
  read: boolean;
  recordingSttStatus: NoteRecordingSttStatus;
  file: {
    id: number;
    durationText: string;
    duration: number;
  };
  media: {
    duration: number;
  };
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  editedAt: string;
  checked?: boolean;
}

export default function useNotesInfiniteQuery(
  queryKey: string[],
  defaultFilter: IDefaultFilter,
  select: any,
) {
  const [searchParams] = useSearchParams();
  const type = searchParams.get('type');
  const sortKey = searchParams.get('sort_key');

  async function fetchServerPage(lastIndex: number = 0): Promise<{
    rows: any[];
    nextOffset: any;
    total: number;
    youtubeTotal: number;
    textTotal: number;
    recordingTotal: number;
  }> {
    const filter = {
      ...{ ...defaultFilter },
      ...(lastIndex !== 0 && { lastIndex }),
      ...(type && { type }),
      ...(sortKey && { sortKey }),
    };

    const {
      data: rows,
      meta: { restCount, youtubeTotal, textTotal, recordingTotal, total },
    } = (await apiClient.get('/notes', filter)) as {
      data: INotesData[];
      meta: {
        restCount: number;
        total: number;
        youtubeTotal: number;
        textTotal: number;
        recordingTotal: number;
      };
    };

    let filterSortKey: 'deletedAt' | 'editedAt' | 'id';

    switch (sortKey) {
      case 'deleted_at':
        filterSortKey = 'deletedAt';
        break;
      case 'edited_at':
        filterSortKey = 'editedAt';
        break;
      case 'id':
        filterSortKey = 'id';
        break;
    }

    let nextOffset;

    nextOffset = restCount > 0 ? rows.at(-1)![filterSortKey!] : undefined;

    return { rows, total, nextOffset: nextOffset, youtubeTotal, textTotal, recordingTotal };
  }

  const { status, data, error, isFetching, isFetchingNextPage, fetchNextPage, hasNextPage } =
    useInfiniteQuery(
      [...queryKey, type, sortKey],
      async (ctx) => {
        return await fetchServerPage(ctx.pageParam);
      },
      {
        getNextPageParam: (_lastGroup, groups) => {
          return _lastGroup.nextOffset;
        },
        select,
        refetchOnWindowFocus: true,
      },
    );

  return { status, data, error, isFetching, isFetchingNextPage, fetchNextPage, hasNextPage };
}
