import { atom } from 'recoil';

export interface IAuthState {
  isAuthStateLoaded: boolean;
  isLogged: boolean;
  isPersistent: boolean;
  email?: string | null;
  displayName?: string | null;
  emailVerified?: boolean;
  providerData?: any;
}

export const authState = atom<IAuthState>({
  key: 'authState',
  default: {
    isAuthStateLoaded: false,
    isLogged: false,
    isPersistent: false,
  },
});
