import React, { useCallback } from 'react';
import {
  IFunctionGroupData,
  IOrganizationData,
} from '@/pages/Organizations/hooks/useOrganizationsQuery';
import styled from '@emotion/styled';
import { getSessionSerializedData } from '@/lib/util/storage';
import OrganizationCard from '@/components/card/OrganizationCard';

type OrganizationsProps = {
  organizations: IOrganizationData[];
  onOrganizationSelect: (id: number) => void;
};

function OrganizationData({ organizations, onOrganizationSelect }: OrganizationsProps) {
  const organization = getSessionSerializedData('organization');

  const onOrganizationClick = useCallback(
    (id: number) => {
      onOrganizationSelect(id);
    },
    [onOrganizationSelect],
  );

  const resolvedTags = (functionGroup: IFunctionGroupData) => {
    return functionGroup && (functionGroup.tags.split(',') as string[]);
  };

  return (
    <StyledOrganizationData>
      {organizations
        .sort((a, b) => {
          if (a.id === organization.id) {
            return -1;
          }

          return 0;
        })
        .map((myOrganization) => {
          return (
            <OrganizationCard
              key={myOrganization.id}
              id={myOrganization.id}
              onClick={onOrganizationClick}
              displayName={myOrganization.displayName}
              credit={myOrganization.credit}
              subscription={myOrganization.subscription}
              isActive={myOrganization.isActive}
              functionGroups={
                myOrganization.functionGroup && resolvedTags(myOrganization.functionGroup)
              }
              isSelected={organization && organization.id === myOrganization.id}
            />
          );
        })}
    </StyledOrganizationData>
  );
}

export default OrganizationData;

const StyledOrganizationData = styled.div``;
