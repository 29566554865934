import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import crypto from 'crypto-js';
import { authState } from '@/atoms/authState';
import ChannelService from '@/lib/channelService';
import { config } from '@/config';

export default function usePrivateChannelService() {
  const [authInfo] = useRecoilState(authState);

  const { isLogged, email } = authInfo;

  useEffect(() => {
    if (isLogged && email) {
      const memberId = email;
      const secretKey = config.channelService.secretKey;

      const memberHash = crypto.HmacSHA256(memberId, crypto.enc.Hex.parse(secretKey)).toString();

      ChannelService.boot({
        pluginKey: config.channelService.pluginKey,
        customLauncherSelector: '.channel-service',
        hideChannelButtonOnBoot: true,
        memberId,
        memberHash,
        profile: {
          email,
        },
      });
    } else if (!isLogged) {
      ChannelService.shutdown();
    }
  }, [isLogged, email]);

  const showMessenger = () => {
    ChannelService.showMessenger();
  };

  const openWorkflow = () => {
    ChannelService.openWorkflow(config.channelService.workflowMonthId);
  };

  return { showMessenger, openWorkflow };
}
