import { useMutation, useQueryClient } from '@tanstack/react-query';
import apiClient from '@/lib/api/apiClient';
import { queryKey } from '@/pages/Notifications/hooks/useNotificationsHistoryQuery';

export default function useNotifications() {
  const queryClient = useQueryClient();

  const notificationRead = async (id: number) => {
    try {
      await apiClient.put(`/notifications/${id}/read`);
      return id;
    } catch (e) {
      throw e;
    }
  };

  return useMutation(notificationRead, {
    onMutate: async (variables) => {
      await queryClient.cancelQueries([...queryKey, variables]);

      const previousValue = queryClient.getQueryData<any>([...queryKey, variables]);
      return { previousValue };
    },
    onSuccess: async (data, variables) => {
      await queryClient.invalidateQueries(queryKey);
      await queryClient.invalidateQueries(['notification-history-count']);

      const previousThread = queryClient.getQueryData([...queryKey]);

      await queryClient.setQueryData([...queryKey], (old: any) => {
        const pages = old.pages.reduce((acc: any, cur: any) => {
          const rows = cur.rows.map((curPage: any) => {
            if (curPage.notification.id === variables) {
              return {
                ...curPage,
              };
            }

            return curPage;
          });

          return [...acc, { ...cur, rows }];
        }, []);

        return { ...old, pages };
      });
      return { previousThread };
    },

    onError: async () => {},
  });
}
