import { css } from '@emotion/react';
import { Checkbox as AntdCheckbox } from 'antd';
import type { CheckboxProps } from 'antd/es/checkbox';
import React from 'react';
import theme from '@/assets/styles/theme';
import ImgCheckedOfCheckbox from '@/assets/images/form/icon-checked-of-checkbox.svg';

interface TInternalProps extends CheckboxProps {}

const Checkbox = (props: TInternalProps) => {
  const { children, className, ...rest } = props;
  return (
    <AntdCheckbox {...rest} css={styledCheckbox} className={`${className || ''}`}>
      {children}
    </AntdCheckbox>
  );
};

export default Checkbox;

const styledCheckbox = css`
  .ant-checkbox-inner {
    width: 1.8rem;
    height: 1.8rem;
    border-color: ${theme.formColor.default};
    border-radius: ${theme.formRadius.checkbox};

    &:after {
      content: '';
      left: 50%;
      width: 1rem;
      height: 1rem;
      border: none;
      background: url(${ImgCheckedOfCheckbox}) no-repeat center center;
      transform: translate(-50%, -50%);
      transition: none;
      vertical-align: middle;
    }
  }

  &:hover {
    .ant-checkbox-inner {
      border-color: ${theme.formColor.default};
    }
  }

  .ant-checkbox-input:focus {
    + .ant-checkbox-inner {
      border-color: ${theme.formColor.default};
    }
  }

  .ant-checkbox {
    &.ant-checkbox-checked:not(.ant-checkbox-disabled) {
      .ant-checkbox-inner {
        border-color: ${theme.formColor.focus};
        background-color: ${theme.formColor.focus};

        &:hover {
          background-color: ${theme.color.primary.hover};
        }
      }

      &:after {
        display: none;
      }
    }

    + span {
      padding-left: 1rem;
      padding-right: 0;
    }
  }

  &.ant-checkbox-wrapper {
    + .ant-checkbox-wrapper {
      margin-left: 0;
    }
  }
`;
