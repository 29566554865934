import React from 'react';
import { Popover as AntdPopover } from 'antd';
import { PopoverProps } from 'antd/es/popover';

function Popover({ className, children, ...restProps }: PopoverProps) {
  return (
    <AntdPopover overlayClassName={className} {...restProps}>
      {children}
    </AntdPopover>
  );
}

export default Popover;
