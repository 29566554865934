export const termsToKo = (name: string) => {
  switch (name) {
    case 'policy_privacy':
      return '개인정보 수집 및 이용 동의';
    case 'policy_service':
      return '서비스 이용 약관';
    case 'policy_data_collect':
      return '서비스 품질 향상을 위한 데이터 수집';
    case 'policy_privacy_processing':
      return '개인정보 처리방침';
    case 'policy_marketing':
      return '마케팅 목적의 개인정보 수집 및 이용 동의';
    case 'policy_event':
      return '이벤트 및 혜택 알림 수신 동의';
    default:
      return '';
  }
};

export const inviteCodeDescription = [
  '공유받은 초대코드를 등록해 주세요.',
  '워크스페이스를 추가로 등록하시면 워크스페이스를 선택하여 소보로를 이용할 수 있습니다.',
];

export const userKeywordDescription = [
  '등록된 단어는 더욱 정확하게 인식됩니다.',
  '전문 용어나 자주 사용하는 단어를 등록하고 인식률을 높여보세요.',
  '실시간 자막 실행 중에만 등록된 단어가 적용됩니다.',
];
