import React from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@emotion/react';
import GlobalStyles from '@/assets/styles/GlobalStyles';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { HelmetProvider } from 'react-helmet-async';
import SEOMetaTag from './components/SEOMetaTag';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { RecoilRoot } from 'recoil';
import 'antd/dist/reset.css';
import '@/assets/styles/tailwind.css';
import '@/assets/styles/slider.css';
import theme from '@/assets/styles/theme';

const { version } = require('../package.json');

const queryClient = new QueryClient();

const REACT_APP_SENTRY_RELEASE = process.env.REACT_APP_SENTRY_RELEASE;
const REACT_APP_ENV = process.env.REACT_APP_ENV;

/**
 * Sentry 설정
 */
Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DNS,
  integrations: [new BrowserTracing()],
  environment: REACT_APP_ENV,
  allowUrls: [
    'https://app-dev.sovoro.kr',
    'https://app-staging.sovoro.kr',
    'https://app.sovoro.kr',
  ],
  tracesSampleRate: process.env.NODE_ENV === 'production' ? 0 : 1.0,
  release: version + '_' + REACT_APP_SENTRY_RELEASE + '_' + REACT_APP_ENV,
  ignoreErrors: [
    /Cannot resolve a Slate point from DOM point/,
    'TypeError',
    'FirebaseError',
    'ReferenceError',
  ],
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <BrowserRouter>
    <HelmetProvider>
      <SEOMetaTag />
      <ThemeProvider theme={theme}>
        <RecoilRoot>
          <QueryClientProvider client={queryClient}>
            <App />
          </QueryClientProvider>
        </RecoilRoot>
      </ThemeProvider>
      <GlobalStyles />
    </HelmetProvider>
  </BrowserRouter>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
