import React from 'react';
import styled from '@emotion/styled';
import { Switch as AntdSwitch } from 'antd';
import theme from '@/assets/styles/theme';

type Props = {
  checked: boolean;
  onChange: (checked: boolean) => void;
};

function Switch({ checked, onChange }: Props) {
  return <StyledSwitch checked={checked} onChange={onChange} />;
}

export default Switch;

export const StyledSwitch = styled(AntdSwitch)`
  background-color: ${theme.color.gray.gray200};

  &.ant-switch-checked {
    background-color: ${theme.color.primary.default};
  }
`;
