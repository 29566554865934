import { useQuery } from '@tanstack/react-query';
import { toDateTime } from '@/lib/util/format';
import { AxiosError } from 'axios';
import apiClient from '@/lib/api/apiClient';
import { ScriptParagraph, TpxSpeaker } from '@/components/editor/Paragraph';
import { JsonWaveformData } from 'waveform-data';

export type ISO8601TimestampType = string;

export enum NoteRecordingSttStatus {
  NONE = 'none',
  READY = 'ready',
  UPLOADING = 'uploading',
  UPLOADING_FAILED = 'upload_failed',
  PROCEEDING = 'proceeding',
  COMPLETED = 'completed',
  ERROR = 'error',
}

export const descriptionStr: { [key in NoteRecordingSttStatus]?: string } = {
  [NoteRecordingSttStatus.READY]: '업로드 대기 중...',
  [NoteRecordingSttStatus.UPLOADING]: '업로드 중...',
  [NoteRecordingSttStatus.UPLOADING_FAILED]: '업로드 실패',
  [NoteRecordingSttStatus.PROCEEDING]: '변환 중...',
};

export interface INoteData {
  id: number;
  title: string;
  text: string;
  type: string;
  createdAt: ISO8601TimestampType;
  updatedAt: ISO8601TimestampType;
  deletedAt: ISO8601TimestampType;
  editedAt: ISO8601TimestampType;
  durationNum?: number;
  script: INoteScript;
  file?: INoteFile;
  waveform?: IWaveform;
  media?: IMedia;
  recordingSttStatus: NoteRecordingSttStatus;
}

export interface INoteScript {
  id: number;
  format: 'tpx' | 'txt';
  formatVersion: number;
  content: { value: ScriptParagraph[] | string; speakers?: TpxSpeaker[] };
  createdAt: ISO8601TimestampType;
  updatedAt: ISO8601TimestampType;
}

export interface IMedia {
  id: number;
  duration: number;
  mediaId: string;
  providerName: string;
  thumbnailUrl: string;
}

export interface INoteFile {
  'id': number;
  'duration': number;
  'durationText': string;
  'bucket': string;
  'key': string;
  'size': number;
  'downloadLink': string;
}

export interface IWaveform {
  'id': number;
  'data': JsonWaveformData;
}

export const queryKey = ['note'];

export const useNoteQuery = (noteId: number) => {
  const { data, isLoading, error } = useQuery<{ data: INoteData }, AxiosError, INoteData>(
    [...queryKey, noteId],
    async () => {
      return await apiClient.get(`/notes/${noteId}`);
    },
    {
      retry: false,
      refetchOnMount: 'always',
      refetchInterval: false,
      refetchOnWindowFocus: false,
      cacheTime: 0,
      select: ({ data }) => {
        if (data.recordingSttStatus === 'proceeding') {
          return {
            ...data,
            createdAt: toDateTime(data.createdAt),
            updatedAt: toDateTime(data.updatedAt),
            editedAt: toDateTime(data.editedAt),
            deletedAt: data.deletedAt ? toDateTime(data.deletedAt) : data.deletedAt,
            durationNum: (data.file && data.file.duration) || (data.media && data.media.duration),
          };
        }

        if (data.script.format === 'tpx') {
          for (const paragraph of data.script.content.value) {
            // @ts-ignore
            delete paragraph.text;
          }
        }

        return {
          ...data,
          createdAt: toDateTime(data.createdAt),
          updatedAt: toDateTime(data.updatedAt),
          editedAt: toDateTime(data.editedAt),
          deletedAt: data.deletedAt ? toDateTime(data.deletedAt) : data.deletedAt,
          durationNum: (data.file && data.file.duration) || (data.media && data.media.duration),
        };
      },
    },
  );

  return { data, isLoading, error };
};
