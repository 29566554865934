import React, { useCallback, useEffect, useState } from 'react';
import SEOMetaTag from '@/components/SEOMetaTag';
import PageHeader from '@/components/layout/PageHeader';
import Button from '@/components/button/Button';
import theme from '@/assets/styles/theme';
import AppBodyLayout from '@/components/app/AppBodyLayout';
import { useRecoilState } from 'recoil';
import { authState } from '@/atoms/authState';
import { auth } from '@/lib/auth/firebase-auth';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { signInWithCustomToken, signInWithEmailAndPassword, updatePassword } from 'firebase/auth';
import useWithdrawMutation from '@/pages/Account/hooks/useWithdrawMutation';
import useLogoutDevicesMutation from '@/pages/Account/hooks/useLogoutDevicesMutation';
import ModalToCheckPassword from './ModalToCheckPassword';
import ModalToChangePassword from './ModalToChangePassword';
import { setSerializedData, setSessionSerializedData } from '@/lib/util/storage';
import { useOrganizationsQuery } from '../Organizations/hooks/useOrganizationsQuery';
import { organizationState } from '@/atoms/organizationState';
import apiClient from '@/lib/api/apiClient';
import dayjs from 'dayjs';
import styled from '@emotion/styled';
import { useUserQuery } from '@/hooks/useUserQuery';
import Loading from '@/components/loading/Loading';
import { useSubscriptionQuery } from '@/pages/Subscriptions/hooks/useSubscriptionQuery';
import { ReactComponent as ImgKakao } from '@/assets/images/icon/socialLogin/kakao.svg';
import { ReactComponent as ImgNaver } from '@/assets/images/icon/socialLogin/naver.svg';
import { useDevicesQuery } from '@/pages/Device/hooks/useDevicesQuery';
import { media } from '@/assets/styles/GlobalStyles';
import { Form } from 'antd';
import InputText from '@/components/inputText/InputText';
import messageInForm from '@/assets/messageForm';
import useUserInfoMutation from '@/pages/Account/hooks/useUserInfoMutation';
import Modal from '@/components/popup/Modal';
import { styledHeadline2 } from '@/assets/styles/styledText';
import OrganizationData from '@/pages/Organizations/OrganizationData';
import ModalToWithdrawal from '@/pages/Account/ModalToWithdrawal';
import useDevicesDeleteMutation from '@/pages/Device/hooks/useDevicesDeleteMutation';
import AccountErrorModal from '@/pages/Account/AccountErrorModal';
import useUserAccountEmailMutation from '@/pages/Account/hooks/useUserAccountEmailMutation';
import useFunctionGroupCheck from '@/hooks/useFunctionGroupCheck';

const resolvedProductName = (productUid: string | null | undefined) => {
  switch (productUid) {
    case 'e329caf6-0113-11ef-927f-0af240c9f508':
      return '소보로 플러스 월간';
    case 'e329cb1a-0113-11ef-927f-0af240c9f511':
      return '소보로 플러스 연간';
    case 'e329cb1a-0113-11ef-927f-0af240c9f510':
      return '소보로 플러스 월간';
    case 'e329cacd-0113-11ef-927f-0af240c9f508':
      return '소보로 플러스 연간';
    default:
      return '없음';
  }
};

function Account() {
  const navigate = useNavigate();
  const planPayment = useFunctionGroupCheck('planPayment');

  const [openConfirm, setOpenConfirm] = useState(false);
  const [password, setPassword] = useState('');

  const { mutate: withdraw } = useWithdrawMutation();
  const { mutate: logoutDevices } = useLogoutDevicesMutation();

  const { data: userData, isLoading: userLoading } = useUserQuery();
  const { data: subscriptionData, isLoading: subscriptionLoading } = useSubscriptionQuery();
  const { data: deviceData, isLoading: deviceLoading } = useDevicesQuery();

  const { data: organizations } = useOrganizationsQuery();

  const { mutateAsync: userMutate, isLoading: isUserMutateLoading } = useUserInfoMutation();
  const { mutateAsync: deviceMutate } = useDevicesDeleteMutation();
  const { mutate: userAccountEmailMutate } = useUserAccountEmailMutation();

  const [organizationInfo, setOrganization] = useRecoilState(organizationState);
  const [authInfo] = useRecoilState(authState);
  const { email } = authInfo;

  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState<{
    title: string;
    content: string | React.ReactElement;
  }>({
    title: '',
    content: '',
  });

  const [loading, setLoading] = useState(false);
  const [isModify, setIsModify] = useState(false);
  const [form] = Form.useForm();

  const [searchParams] = useSearchParams();
  const selectWorkspace = searchParams.get('select-workspace');

  useEffect(() => {
    if (selectWorkspace && selectWorkspace === 'true') {
      setOrganizationModalVisible(true);
    }
  }, [selectWorkspace]);

  const closeErrorModal = () => {
    setErrorModalVisible(false);
  };

  /* [팝업] 비밀번호 확인 */
  const [isModalVisible, setIsModalVisible] = useState(false);
  const handleShowModal = () => {
    if (userData?.accounts && userData?.accounts.find((account) => account.provider === 'email')) {
      setIsModalVisible(true);
    } else {
      setIsModalVisible2(true);
    }
  };
  const handleOkModal = useCallback(
    async (values: any) => {
      const { password } = values;

      setLoading(true);

      try {
        if (email) {
          await signInWithEmailAndPassword(auth, email, password);

          setLoading(false);

          setIsModalVisible(false);

          setIsModalVisible2(true);
        }
      } catch (error: any) {
        if (error.code === 'auth/wrong-password') {
          setErrorMessage({
            title: '패스워드를 확인해주세요.',
            content: '고객님의 정보를 다시 확인하시고 입력해주세요.',
          });
        } else if (error.code === 'auth/too-many-requests') {
          setErrorMessage({
            title: '잠시 후 다시 시도해주세요.',
            content: (
              <>
                비정상적인 활동으로 차단되었습니다.
                <br />
                잠시 후 다시 시도하면 차단이 해제됩니다.
              </>
            ),
          });
        } else {
          setErrorMessage({
            title: '알 수 없는 오류입니다.',
            content: '잠시 후 다시 시도해주세요.',
          });
        }
        setLoading(false);
        setIsModalVisible(false);
        setErrorModalVisible(true);
      }
    },
    [email],
  );
  const handleCancelModal = useCallback(() => {
    setIsModalVisible(false);
  }, []);

  /* [팝업] 비밀번호 수정 */
  const [isModalVisible2, setIsModalVisible2] = useState(false);

  const handleOkModal2 = useCallback(async (values: any) => {
    setPassword(values.password);

    setIsModalVisible2(false);
    setIsModalVisible3(false);
    setOpenConfirm(true);
  }, []);
  const handleCancelModal2 = useCallback(() => {
    setIsModalVisible2(false);
    setPassword('');
  }, []);

  /* [팝업] 비밀번호 등록/변경 완료 */
  const [isModalVisible3, setIsModalVisible3] = useState(false);
  const [successText, setSuccessText] = useState('');
  const handleOkModal3 = useCallback(async () => {
    setIsModalVisible3(false);
  }, []);

  const handleCancelModal3 = useCallback(async () => {
    setIsModalVisible3(false);
  }, []);

  /* [팝업] 초대코드로 회원가입 페이지 이동 */
  const handleInviteCode = useCallback(() => {
    navigate('/account/invite-codes');
  }, []);

  /* [팝업] 회원 탈퇴 */
  const [withdrawalModalVisible, setWithdrawalModalVisible] = useState(false);
  const handleShowWithdrawalModal = () => {
    setWithdrawalModalVisible(true);
  };
  const handleOkWithdrawalModal = useCallback(
    async (values: any) => {
      setLoading(true);

      withdraw({
        reason: values.reason,
        content: values.content,
      });

      setWithdrawalModalVisible(false);
      setLoading(false);
    },
    [navigate, withdraw],
  );
  const handleCancelWithdrawalModal = useCallback(() => {
    setWithdrawalModalVisible(false);
  }, []);

  const [organizationModalVisible, setOrganizationModalVisible] = useState(false);
  const handleShowOrganizationModal = useCallback(() => {
    setOrganizationModalVisible(true);
  }, [setOrganizationModalVisible]);

  const handleCancelOrganizationModal = useCallback(() => {
    setOrganizationModalVisible(false);
  }, []);

  const handleConfirmOk = async () => {
    setLoading(true);

    try {
      if (auth.currentUser) {
        if (
          userData?.accounts &&
          userData?.accounts.find((account) => account.provider === 'email')
        ) {
          await updatePassword(auth.currentUser, password);
          setSuccessText('변경');
        } else {
          try {
            const { customToken } = await apiClient.post('/custom-token');

            await signInWithCustomToken(auth, customToken as string);
            await updatePassword(auth.currentUser, password);
            setSuccessText('등록');
            userAccountEmailMutate();
          } catch (e) {
            setErrorMessage({
              title: '알 수 없는 오류입니다.',
              content: '잠시 후 다시 시도해주세요.',
            });
          }
        }

        await setIsModalVisible3(true);
        await logoutDevices();

        setLoading(false);
        setOpenConfirm(false);
        setPassword('');
      }
    } catch (error) {
      setLoading(false);
      setOpenConfirm(false);
      setPassword('');
      setErrorMessage({
        title: '알 수 없는 오류입니다.',
        content: '잠시 후 다시 시도해주세요.',
      });
    }
  };

  const handleConfirmCancel = () => {
    setOpenConfirm(false);
  };

  const [deviceOpen, setDeviceOpen] = useState(false);
  const [selectedDeviceId, setSelectedDeviceId] = useState(0);
  /**
   * 기기 연결 해제
   */
  const handleDisconnectModal = useCallback(
    async (deviceId: number) => {
      await setSelectedDeviceId(deviceId);
      await setDeviceOpen(true);
    },
    [setDeviceOpen, setSelectedDeviceId],
  );

  const handleDisconnect = useCallback(async () => {
    await deviceMutate(selectedDeviceId);
    await setDeviceOpen(false);
    await setSelectedDeviceId(0);
  }, [setDeviceOpen, setSelectedDeviceId, deviceMutate, selectedDeviceId]);

  const handleCloseDisconnectModal = useCallback(() => {
    setDeviceOpen(false);
    setSelectedDeviceId(0);
  }, [setDeviceOpen, setSelectedDeviceId]);

  /**
   * 사용자가 선택한 워크스페이스 localStorage 에 저장
   */
  const onOrganizationSelect = useCallback(
    async (id: number) => {
      const organizationLocal = {
        email: email,
        id: id,
        persist: true,
      };

      setSerializedData('organization-persist', organizationLocal);

      setSessionSerializedData('organization', organizationLocal);

      const { data } = await apiClient.get(`organizations/${id}`);

      setOrganization(data);
      setOrganizationModalVisible(false);
    },
    [navigate, email, setOrganization],
  );

  const onModifyUserInfo = () => {
    setIsModify(true);
    form.setFieldValue('displayName', userData?.displayName);
    form.setFieldValue('mobile', resolvedMobileNumber(userData?.mobile));
  };

  const resolvedAccount = (provider: string) => {
    const res = userData?.accounts.find((account) => account.provider === provider);
    if (res) {
      return res.email;
    }
    return '연동된 계정 없음';
  };

  const resolvedMobileNumber = (mobile: string | undefined) => {
    if (mobile) {
      return mobile.replace(/-/g, '');
    }
    return mobile;
  };

  const formatPhoneNumber = (phoneNumber: string | undefined) => {
    if (phoneNumber) {
      const digits = phoneNumber.replace(/\D/g, '');

      return `${digits.slice(0, 3)}-${digits.slice(3, 7)}-${digits.slice(7)}`;
    }

    return '';
  };

  /*
   * 회원정보 수정
   */
  const onFinish = async (values: any) => {
    await userMutate(values);
    await setIsModify(false);
  };

  if (userLoading || subscriptionLoading || deviceLoading) {
    return <Loading />;
  }

  return (
    <>
      <SEOMetaTag title="내 계정" />
      <AppBodyLayout
        pageHeader={
          <PageHeader header={<h1 className="title text-ellipsis overflow-hidden">내 계정</h1>} />
        }
      >
        <Form form={form} onFinish={onFinish}>
          <AccountItem>
            <dl>
              <dt className="title">
                <AccountItemTitle>회원 정보</AccountItemTitle>
              </dt>
              <dd className="description">
                {isModify ? (
                  <>
                    <Form.Item shouldUpdate>
                      {() => (
                        <Button
                          size="small"
                          htmlType="submit"
                          disabled={
                            !!form.getFieldsError().filter(({ errors }) => errors.length).length
                          }
                          loading={isUserMutateLoading}
                        >
                          변경사항 적용
                        </Button>
                      )}
                    </Form.Item>
                    <Button
                      size="small"
                      design="quaternary"
                      className="modify-cancel"
                      loading={isUserMutateLoading}
                      onClick={() => setIsModify(false)}
                    >
                      취소
                    </Button>
                  </>
                ) : (
                  <Button size="small" design="quaternary" onClick={onModifyUserInfo}>
                    정보 수정
                  </Button>
                )}
              </dd>
            </dl>
            <dl>
              <dt className="title">이메일</dt>
              <dd className="description">{userData?.email}</dd>
            </dl>
            <dl>
              <dt className="title">이름</dt>
              <dd className="description">
                {isModify ? (
                  <Form.Item
                    name="displayName"
                    rules={[
                      { required: true, message: messageInForm.name.error },
                      {
                        pattern: new RegExp(/^[a-zA-Zㄱ-힣\s]{2,10}$/),
                        message: messageInForm.name.error2,
                      },
                    ]}
                  >
                    <InputText
                      autoComplete="off"
                      showCount={true}
                      maxLength={10}
                      onBlur={() =>
                        form.setFieldValue('displayName', form.getFieldValue('displayName').trim())
                      }
                    />
                  </Form.Item>
                ) : (
                  <span>{userData?.displayName}</span>
                )}
              </dd>
            </dl>
            <dl>
              <dt className="title">휴대전화</dt>
              <dd className="description">
                {isModify ? (
                  <Form.Item
                    name="mobile"
                    normalize={(value) => value.replace(/\D/g, '')}
                    rules={[
                      {
                        pattern: new RegExp(/^(01)\d{9}$/),
                        message: messageInForm.mobile.error2,
                      },
                    ]}
                  >
                    <InputText autoComplete="off" type="tel" maxLength={11} />
                  </Form.Item>
                ) : (
                  <span>{formatPhoneNumber(userData?.mobile)}</span>
                )}
              </dd>
            </dl>
          </AccountItem>
        </Form>
        <AccountItem>
          <dl className="password">
            <dt className="title">
              <AccountItemTitle>비밀번호</AccountItemTitle>
            </dt>
            <dd className="description">{`${
              userData?.accounts &&
              userData?.accounts.find((account) => account.provider === 'email')
                ? '등록됨'
                : '등록 안됨'
            }`}</dd>
            <Button size="small" design="secondary" className="gray" onClick={handleShowModal}>
              {`${
                userData?.accounts &&
                userData?.accounts.find((account) => account.provider === 'email')
                  ? '비밀번호 변경'
                  : '비밀번호 등록'
              }`}
            </Button>
          </dl>
        </AccountItem>
        <AccountItem>
          <dl>
            <dt className="title long">
              <AccountItemTitle>워크스페이스 정보</AccountItemTitle>
            </dt>
          </dl>
          <dl className="workspace">
            <dt className="title">이름</dt>
            <dd className="description">{organizationInfo.displayName}</dd>
            <WorkspaceButtons>
              {organizations.length > 1 && (
                <Button
                  size="small"
                  design="secondary"
                  className="gray"
                  onClick={handleShowOrganizationModal}
                >
                  다른 워크스페이스로 전환
                </Button>
              )}
              {!organizationInfo.isManage && (
                <Button size="small" design="secondary" className="gray" onClick={handleInviteCode}>
                  초대코드로 가입
                </Button>
              )}
            </WorkspaceButtons>
          </dl>
          {planPayment && (
            <dl>
              <dt className="title">이용중인 플랜</dt>
              <dd className="description">{resolvedProductName(subscriptionData?.product.uid)}</dd>
            </dl>
          )}
        </AccountItem>
        <AccountItem>
          <dl>
            <dt className="title long">
              <AccountItemTitle>소셜 계정 연동</AccountItemTitle>
            </dt>
          </dl>
          <dl>
            <dt className="title">
              <ImgKakao /> 카카오
            </dt>
            <dd className="description">{resolvedAccount('kakao')}</dd>
          </dl>
          <dl>
            <dt className="title">
              <ImgNaver /> 네이버
            </dt>
            <dd className="description">{resolvedAccount('naver')}</dd>
          </dl>
        </AccountItem>
        <AccountItem className="device">
          <dl>
            <dt className="title long">
              <AccountItemTitle>등록 기기 관리</AccountItemTitle>
            </dt>
          </dl>
          {deviceData.length === 0 && <dt className="no-device">등록된 기기 없음</dt>}
          {deviceData.length > 0 &&
            deviceData.map((device) => {
              return (
                <dl className="device">
                  <dt className="title">{`${device.details.name} | ${device.details.model}`}</dt>
                  <dd className="description">{`마지막 접속일: ${dayjs(device.loggedAt).format(
                    'YYYY.MM.DD',
                  )}`}</dd>
                  <Button
                    size="small"
                    design="secondary"
                    className="gray"
                    onClick={() => handleDisconnectModal(device.uid)}
                  >
                    연결 해제
                  </Button>
                </dl>
              );
            })}
        </AccountItem>
        <AccountInformation>
          <AccountItemTitle>안내 사항</AccountItemTitle>
          <ul>
            <li>등록 기기는 모바일과 태블릿 기기만 표시됩니다.</li>
            <li>실시간 자막 서비스는 등록된 기기에서 최대 2대까지 동시 사용이 가능합니다.</li>
            <li>
              연결 해제 시 해당 기기는 즉시 로그아웃됩니다. 실시간 자막을 사용하고 있는 경우
              저장되지 않고 종료됩니다.
            </li>
          </ul>
        </AccountInformation>
        <WithdrawalButton>
          <Button design="tertiary" onClick={handleShowWithdrawalModal}>
            회원 탈퇴
          </Button>
        </WithdrawalButton>
      </AppBodyLayout>
      {/* [팝업] 비밀번호 확인 */}
      {isModalVisible && (
        <ModalToCheckPassword
          visible={isModalVisible}
          onOk={handleOkModal}
          onCancel={handleCancelModal}
          loading={loading}
        />
      )}
      {/* [팝업] 비밀번호 수정 */}
      {isModalVisible2 && (
        <ModalToChangePassword
          visible={isModalVisible2}
          onOk={handleOkModal2}
          onCancel={handleCancelModal2}
          loading={loading}
          isPassword={
            userData?.accounts && userData?.accounts.find((account) => account.provider === 'email')
          }
        />
      )}
      {/* [팝업] 비밀번호 수정 완료 */}
      {isModalVisible3 && (
        <Modal
          // title="비밀번호 변경 완료"
          title={`비밀번호 ${successText} 완료`}
          okText="확인"
          onOk={handleOkModal3}
          closable={false}
          onCancel={handleCancelModal3}
        >
          <p>
            {`비밀번호 ${successText}이 완료되었습니다.`}
            <br />
            {`${successText}된 이메일과 비밀번호로 로그인이 가능합니다.`}
          </p>
        </Modal>
      )}

      {openConfirm && (
        <Modal
          title={`비밀번호를 ${
            userData?.accounts && userData?.accounts.find((account) => account.provider === 'email')
              ? '변경'
              : '등록'
          }하시겠습니까?`}
          onOk={handleConfirmOk}
          okText={`비밀번호 ${
            userData?.accounts && userData?.accounts.find((account) => account.provider === 'email')
              ? '변경'
              : '등록'
          }`}
          cancelText="취소"
          onCancel={handleConfirmCancel}
          loading={loading}
        >
          {`비밀번호 ${
            userData?.accounts && userData?.accounts.find((account) => account.provider === 'email')
              ? '변경'
              : '등록'
          } 시 로그인된 다른 모든 기기에서 로그아웃 처리되며, 생성/녹음 중이던 자막은 저장되지 않습니다.`}
        </Modal>
      )}

      {/* [팝업] 회원 탈퇴 */}
      {withdrawalModalVisible && (
        <ModalToWithdrawal
          visible={withdrawalModalVisible}
          onOk={handleOkWithdrawalModal}
          onCancel={handleCancelWithdrawalModal}
          loading={loading}
        />
      )}

      {/* [팝업] 디바이스 연결 해지 */}
      {deviceOpen && (
        <Modal
          title="등록 기기 연결 해제"
          onOk={handleDisconnect}
          okText="연결 해제"
          cancelText="취소"
          onCancel={handleCloseDisconnectModal}
        >
          연결 해제 시 해당 기기에서 즉시 로그아웃됩니다. 실시간 자막을 사용하고 있는 경우 저장되지
          않고 종료됩니다.
        </Modal>
      )}

      {/*[팝업] 워크스페이스 전환*/}
      {organizationModalVisible && (
        <Modal
          title={
            <OrganizationSelectModalTitle>
              워크스페이스 선택
              <p css={styledHeadline2} className={`mb-[2.4rem] text-left sticky`}>
                <span className="line-break">
                  워크스페이스에 따라 사용할 수 있는 기능, 공지, 사용자 가이드가 다를 수 있습니다.
                </span>
              </p>
            </OrganizationSelectModalTitle>
          }
          open={true}
          width={448}
          onCancel={handleCancelOrganizationModal}
          footer={false}
          bodyStyle={{
            height: 760,
            overflowY: 'auto',
            paddingLeft: 64,
            paddingRight: 64,
            paddingTop: 0,
          }}
        >
          <OrganizationData
            organizations={organizations}
            onOrganizationSelect={onOrganizationSelect}
          />
        </Modal>
      )}

      <AccountErrorModal
        visible={errorModalVisible}
        onCancel={closeErrorModal}
        onOk={closeErrorModal}
        title={errorMessage.title}
        content={errorMessage.content}
      />
    </>
  );
}

export default Account;

const AccountItem = styled.div`
  .ant-form-item-with-help .ant-form-item-extra,
  .ant-form-item-with-help .ant-form-item-explain {
    position: unset;
  }

  .ant-form-item-explain-error {
    position: absolute;
    left: 240px;
    bottom: 16px;
    width: 100%;
  }

  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px 0;

  border-bottom: 1px solid ${theme.color.gray.gray100};

  &.device {
    border-bottom: none;
  }

  dl {
    display: flex;
    align-items: center;
    height: 36px;

    &.device {
      display: grid;
      grid-template-columns: auto 1fr 1fr;
      align-items: center;
      gap: 16px;

      dt {
        width: auto;
      }
    }

    ${media.mobile} {
      height: auto;
    }
  }

  dt {
    width: 100px;
    color: ${theme.color.gray.gray900};
    font-weight: 400;

    display: flex;
    align-items: center;
    gap: 6px;

    svg {
      width: 24px;
      height: auto;
    }

    &.long {
      width: 100%;
    }

    &.no-device {
      margin: 16px 0;
    }
  }

  dd {
    color: ${theme.color.gray.gray500};
    display: flex;
    gap: 10px;
  }

  button {
    font-size: 12px;
    min-height: 30px !important;
    padding: 0 6px !important;
    margin-left: auto;
    font-weight: 400;

    &.gray {
      color: ${theme.color.gray.gray500} !important;
      padding: 0 24px !important;
    }

    &.modify-cancel {
      padding: 0 20px !important;
    }
  }

  ${media.mobile} {
    dl {
      display: flex;
      justify-content: space-between;

      &.password,
      &.workspace {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
        dd {
          justify-content: end;
        }
      }

      &.password button,
      &.workspace > div {
        width: 100%;
        grid-column: 1 / 3;
      }

      &.device {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
        button {
          width: 100%;
        }
      }
    }
  }

  .ant-form-item-control-input input,
  .ant-input-affix-wrapper {
    height: 3.6rem !important;
    background-color: transparent;
    display: flex;
    align-items: center;

    width: 240px;
  }
`;

const AccountItemTitle = styled.div`
  display: flex;
  font-size: 16px;
  font-weight: 700;

  align-items: center;

  button {
    font-size: 12px;
    min-height: 20px !important;
    padding: 0 6px !important;
  }
`;

const WorkspaceButtons = styled.div`
  margin-left: auto;
  display: flex;
  gap: 1.6rem;

  ${media.mobile} {
    display: flex;
    flex-direction: column;
    button {
      width: 100%;
    }
  }
`;

const AccountInformation = styled.div`
  background-color: ${theme.color.menu.backB};
  padding: 18px 30px;

  ul {
    margin-top: 12px;
    list-style: disc;
    padding-left: 20px;
    font-size: 13px;
  }
`;

const WithdrawalButton = styled.div`
  margin-top: 24px;
  margin-bottom: 4.5rem;

  button {
    color: ${theme.color.error.default};
    border: 1px solid ${theme.color.error.default} !important;
    font-weight: 700;
    font-size: 12px;
    padding: 0 24px;

    &:hover {
      color: ${theme.color.error.hover} !important;
    }

    ${media.mobile} {
      width: 100%;
    }
  }
`;

const OrganizationSelectModalTitle = styled.div`
  font-size: 24px;

  padding: 4.4rem 4.4rem 0 4.4rem;

  p {
    margin-top: 3rem;
    color: ${theme.color.gray.gray900};
  }
`;
