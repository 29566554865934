import { useMutation, useQueryClient } from '@tanstack/react-query';
import { queryKey } from '@/hooks/useUserQuery';
import apiClient from '@/lib/api/apiClient';

export default function useUserAccountEmailMutation() {
  const userAccountEmail = async () => {
    try {
      const { data } = await apiClient.post(`/user-accounts/email`);
      return data;
    } catch (e) {
      throw e;
    }
  };

  const queryClient = useQueryClient();
  return useMutation(queryKey, userAccountEmail, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(['user']);
    },
  });
}
