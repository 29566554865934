import apiClient from '@/lib/api/apiClient';
import useOptimisticMutation from '@/hooks/useOptimisticMutation';

const queryKey = ['logout'];

const logout = async (deviceToken: string) => {
  try {
    return await apiClient.post(
      '/logout',
      {},
      {
        headers: {
          'Device-Token': deviceToken,
        },
      },
    );
  } catch (e) {
    console.error(e);
  }
};

export default function useLogoutMutation() {
  return useOptimisticMutation(queryKey, logout, '로그아웃 되었습니다.');
}
