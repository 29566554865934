import { useMutation, useQueryClient } from '@tanstack/react-query';
import { MutationFunction } from '@tanstack/query-core';
import { messageInfo } from '@/components/popup/message';

export default function useOptimisticMutation<
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown,
>(queryKey: string[], mutationFn: MutationFunction<TData, TVariables>, message: string) {
  const queryClient = useQueryClient();

  return useMutation<TData, TError, TVariables, TContext>(mutationFn, {
    onMutate: async () => {
      await queryClient.cancelQueries(queryKey);

      return queryClient.getQueryData(queryKey);
    },
    onError: (err, newUser, previousValue) => {
      queryClient.setQueryData(queryKey, previousValue);
    },
    onSettled: async () => {
      await queryClient.invalidateQueries(queryKey);
    },
    onSuccess: async () => {
      messageInfo({
        content: message,
        noIcon: true,
      });
    },
  });
}
