import { InputProps } from 'antd/lib/input/Input';
import React, { forwardRef } from 'react';
import { StyledTextInput } from '@/components/inputText/styleOfInput';

export interface TInputProps extends InputProps {
  isError?: boolean;
}

const InputText = forwardRef<any, TInputProps>((props, ref) => {
  const { isError, className, placeholder, allowClear, showCount, maxLength, ...rest } = props;
  let { status } = props;

  if (isError) {
    status = 'error';
  }

  return (
    <>
      <StyledTextInput
        ref={ref}
        placeholder={placeholder}
        allowClear={allowClear}
        showCount={showCount}
        maxLength={maxLength}
        className={` ${className || ''}`}
        {...rest}
        status={status}
      />
    </>
  );
});
export default InputText;
