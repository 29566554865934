import React from 'react';
import { Modal } from 'antd';
import InducePaymentFooter from '@/components/popup/InducePaymentModal/InducePaymentFooter';
import styled from '@emotion/styled';
import TopImage from '@/assets/images/inducePayment/top.jpg';
import { ReactComponent as IconClose } from '@/assets/images/icon/icon-close.svg';

interface InducePaymentModalProps {
  open: boolean;
  onSubscribe: (productUid: string) => void;
  onCancel: () => void;
}

function InducePaymentModal({ open, onSubscribe, onCancel }: InducePaymentModalProps) {
  return (
    <StyledInducePaymentModal
      open={open}
      closable={false}
      footer={<InducePaymentFooter onSubscribe={onSubscribe} />}
      width={460}
    >
      <img src={TopImage} alt="top" />
      <CloseButton onClick={onCancel}>
        <IconClose />
      </CloseButton>
    </StyledInducePaymentModal>
  );
}

export default InducePaymentModal;

const StyledInducePaymentModal = styled(Modal)`
  .ant-modal-content {
    overflow: hidden;

    background: ${(props) => props.theme.color.menu.backB};

    padding: 0;
  }

  .ant-modal-footer {
    margin-top: 0;
  }
`;

const CloseButton = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 10;

  cursor: pointer;

  padding: 4px;
`;
