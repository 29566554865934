// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';
import { getAuth } from 'firebase/auth';
import { FirebaseOptions } from '@firebase/app';
import { config } from '@/config';
import { getStorage } from 'firebase/storage';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig: FirebaseOptions = {
  apiKey: config.firebase.apiKey,
  authDomain: config.firebase.authDomain,
  projectId: config.firebase.projectId,
  storageBucket: config.firebase.storageBucket,
  messagingSenderId: config.firebase.messagingSenderId,
  appId: config.firebase.appId,
};

const isIOS = /iPhone|iPad|iPod/i.test(window.navigator.userAgent);

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const messaging = !isIOS ? getMessaging(app) : null;

export const auth = getAuth(app);

export const getMessagingToken = () => {
  return !isIOS && auth.currentUser
    ? getToken(messaging!, {
        vapidKey: process.env.REACT_APP_FIREBASE_MESSAGING_VAPID_KEY,
      })
    : null;
};

export const getSovoroStorage = () => {
  return getStorage();
};
