import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import _ from 'lodash';
import { JsonWaveformData } from 'waveform-data';

export function diffCurrentSecond(startDate: dayjs.Dayjs) {
  return dayjs().diff(startDate, 'second');
}

export const toDateTime = (date: string, format = 'YYYY-MM-DD HH:mm:ss') => {
  if (!date) {
    return '-';
  }
  return dayjs(date).format(format);
};

export const toDateTimeNote = (date: string) => {
  if (!date) {
    return '-';
  }

  if (dayjs(date).year() === dayjs().year()) {
    return dayjs(date).format('MM.DD HH:mm');
  }
  return dayjs(date).format('YYYY.MM.DD HH:mm');
};

export const timestamp = (seconds: number | string, format = 'HH:mm:ss.SSS'): string => {
  dayjs.extend(duration);
  return dayjs.duration(Number(seconds), 'seconds').format(format);
};

type JsonWaveform = {
  bits: number;
  channels: number;
  data: number[];
  length: number;
  sample_rate: number;
  samples_per_pixel: number;
  version: number;
};

export const toSnakeCase = (data: JsonWaveformData) => {
  const res = _.mapKeys(data, (value, key) => _.snakeCase(key));
  return res as JsonWaveform;
};

export const toHHMMSS = (duration: number) => {
  const hour = Math.floor(duration / 3600);
  const min = Math.floor((duration - hour * 3600) / 60);
  const second = duration - hour * 3600 - min * 60;

  return `${hour > 0 ? `${hour}시간` : ''} ${min > 0 ? `${min}분` : ''} ${second}초`;
};

export const durationFormat = (duration: number) => {
  const hour = Math.floor(duration / 3600);
  const min = Math.floor(duration / 60);

  const second = duration - hour * 3600 - Math.floor((duration - hour * 3600) / 60) * 60;

  if (!duration) {
    return '';
  }

  if (duration < 60) {
    return `${Math.floor(duration)}초`;
  }

  return `${second >= 30 ? `${min + 1}분` : `${min}분`}`;
};

export const fileSizeFormat = (size: number, isUnit: boolean) => {
  if (size < 1024) {
    return size + `${isUnit ? ' B' : ''}`;
  }
  if (size < 1024 * 1024) {
    return (size / 1024).toFixed(2) + `${isUnit ? ' KB' : ''}`;
  }
  if (size < 1024 * 1024 * 1024) {
    return (size / (1024 * 1024)).toFixed(2) + `${isUnit ? ' MB' : ''}`;
  }
  return (size / (1024 * 1024 * 1024)).toFixed(2) + `${isUnit ? ' GB' : ''}`;
};

export const subscribeFormat = (subscribedAt: string, willRenewalAt: string) => {
  return `${toDateTime(subscribedAt, 'YYYY.MM.DD')} ~ ${dayjs(willRenewalAt).format('YYYY.MM.DD')}`;
};

export const numberWithCommas = (n: number) => {
  return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const formatCardNumber = (cardNumber: string) => {
  return (
    cardNumber.slice(0, 4) +
    '-' +
    cardNumber.slice(4, 8) +
    '-' +
    cardNumber.slice(8, 12) +
    '-' +
    cardNumber.slice(12)
  );
};
