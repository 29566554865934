import { useQuery } from '@tanstack/react-query';
import apiClient from '@/lib/api/apiClient';

export const queryKey = ['notification-history-count'];

export const useNotificationHistoryCountQuery = () => {
  const { data, isLoading, error } = useQuery([...queryKey], async () => {
    return (await apiClient.get(
      '/notification-history-count',
      {},
      {
        headers: {
          'x-platform': 'web',
        },
      },
    )) as { data: { count: number } };
  });

  return { data: data ? data.data : null, isLoading, error };
};
