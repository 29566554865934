import { css } from '@emotion/react';
import theme from '@/assets/styles/theme';
import { media } from '@/assets/styles/GlobalStyles';
import styled from '@emotion/styled';

export const styledLayoutWrap = css`
  height: 100%;
  background-color: #fff;
`;

/** LayoutAside **/
const layoutAsideWidth = '24rem';

export const styledLayoutAside = css`
  width: ${layoutAsideWidth};
  padding: 0;
  border-right: 1px solid ${theme.color.line};
  color: #141414;
  background-color: ${theme.color.menu.backC};

  .btn-gnb-close {
    display: none;
  }

  ${media.pc} {
    &.isHidden {
      margin-left: -${layoutAsideWidth};
    }

    &:hover {
      .btn-gnb-close {
        display: block;
      }
    }
  }

  &.mobile {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 10;

    &.isHidden {
      transform: translateX(-${layoutAsideWidth});
    }
  }
`;

export const styledLayoutAsideDim = css`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
  background-color: rgba(32, 32, 32, 0.2); ;
`;

/** LayoutBody **/
export const styledLayoutContentWrap = css`
  overflow: hidden;

  ${media.mobile} {
    overflow-y: scroll;
  }
`;

/** LayoutBodyMain **/
export const styledLayoutContent = css`
  overflow: hidden;
  overflow-y: auto;

  ${media.pc} {
    padding: 0 3rem;

    > div {
      padding: 0 1rem;
    }
  }

  ${media.mobile} {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .detail & {
    padding: 0;

    > div {
      padding: 0;
    }
  }

  .infinite & {
    padding: 0;

    > div {
      padding: 0;
    }

    .ant-list {
      ${media.pc} {
        padding: 0 3rem;
      }

      ${media.mobile} {
        padding-left: 2rem;
        padding-right: 2rem;
      }
    }
  }
`;

/** LayoutCenterAlign **/
export const StyledWrapLayoutCenterAlign = styled('div')`
  overflow: auto;
  height: 100%;

  ${media.mobile} {
    width: 100%;
  }
`;

export const StyledLayoutCenterAlignHeader = styled('header')(() => {
  return `
    width: 100%;
    padding: 120px 0 0;
    display: flex;
    justify-content: center;
    
    &.non-logo {
      display: none;
    }
    
    ${media.mobile} {
      padding: 80px 0 0;
    }
  `;
});

export const layoutCenterAlignPCMinWidth = 360 - 1;

export const StyledLayoutCenterAlign = styled('div')`
  margin: auto;

  ${media.mobile} {
    width: 100%;
  }
`;

export const StyledLayoutCenterAlignInner = styled('section')(
  (props: { scroll: boolean }) => css`
    position: relative;

    ${props.scroll ? 'overflow-y: auto;' : 'overflow: visible;'}

    display: flex;
    flex-direction: column;

    width: 400px;
    padding: 64px 40px 40px;

    border-radius: 6px;

    &.terms {
      padding: 0;
      overflow-y: hidden;

      .terms-contents {
        overflow-y: scroll;
        padding: 64px 40px 40px;
      }
    }

    ${media.mobile} {
      box-shadow: none;
      border: none;
      min-height: unset;
      height: 100%;
      padding-top: 40px;

      &.non-logo {
        margin-top: 0;
      }

      &.terms {
        > div:last-of-type {
          width: 400px;
        }

        .terms-contents {
          padding: 0 40px 40px;
        }
      }
    }

    ${media.mobile2} {
      &.terms > div:last-of-type {
        width: 100%;
      }
    }

    ${media.custom(layoutCenterAlignPCMinWidth)} {
      width: 100%;

      .line-break {
        display: inline;
      }
    }
  `,
);

interface StyledPageHeaderProps {
  hideNav?: boolean;
  mobileSize?: boolean;
}

/** 스타일 **/
export const StyledPageHeader = styled('header')((props: StyledPageHeaderProps) => {
  return `
      ${
        props.hideNav
          ? `
          position: relative;
          padding-left: 3rem;
        
          .btn-open-aside {
            position: absolute;
            top: 2px;
            left: -1rem;
          }
        `
          : `
          .btn-open-aside {
            display: none;
          }
        `
      }
      
      overflow: hidden;
   
      .title {
        font-weight: 700;
        font-size: 2.4rem;
        line-height: 139.9%;
        text-align: left;
      }
      
      h1.title {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        height: 3.6rem;
      }
  `;
});

export const styledPageHeader = css`
  border-bottom: 1px solid ${theme.color.line2};
  padding: 4rem 3rem 2rem;
  color: ${theme.color.gray.gray900};

  ${media.mobile} {
    display: flex !important;
    flex-wrap: wrap;
    padding: 1.8rem 2rem;

    h1 {
      font-size: 1.8rem !important;
      display: flex;
      align-items: center;
    }

    &.detail {
      padding: 2rem 2rem 1.6rem 2rem !important;
    }
  }

  &.detail {
    padding: 2rem;
  }

  .layout-body:not(.detail) & {
    margin-left: 3rem;
    margin-right: 3rem;
    padding-left: 0;
    padding-right: 0;
  }

  ${media.mobile} {
    padding-left: 2rem;
    padding-right: 2rem;

    .layout-body:not(.detail) & {
      margin-left: 2rem;
      margin-right: 2rem;
    }
  }

  .wrap-aside {
    ${media.pc} {
      margin-left: 4rem;
    }
  }
`;

export const styledDetailHeader = css``;
