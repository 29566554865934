type TConfig = {
  env: {
    apiHost: string;
    apiVersion: string;
    webHost: string;
  };
  channelService: {
    pluginKey: string;
    secretKey: string;
    workflowMonthId: string;
  };
  firebase: {
    apiKey: string;
    authDomain: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId: string;
    appId: string;
  };
  recognitionChannel: {
    host: string;
  };
  naver: {
    clientId: string;
    callbackUrl?: string;
  };
  kakao: {
    javascriptKey: string;
    redirectUri: string;
  };
};

export const config: TConfig = {
  env: {
    apiHost: process.env.REACT_APP_API_HOST!,
    apiVersion: process.env.REACT_APP_API_VERSION!,
    webHost: process.env.REACT_APP_WEB_HOST!,
  },
  channelService: {
    pluginKey: process.env.REACT_APP_CHANNEL_SERVICE_PLUGIN_KEY!,
    secretKey: process.env.REACT_APP_CHANNEL_SERVICE_SECRET_KEY!,
    workflowMonthId: process.env.REACT_APP_CHANNEL_SERVICE_WORKFLOW_MONTH_ID!,
  },
  firebase: {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY!,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN!,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID!,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET!,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID!,
    appId: process.env.REACT_APP_FIREBASE_APP_ID!,
  },
  recognitionChannel: {
    host: process.env.REACT_APP_RECOGNITION_CHANNEL_HOST!,
  },
  naver: {
    clientId: process.env.REACT_APP_NAVER_CLIENT_ID!,
    callbackUrl: process.env.REACT_APP_NAVER_CALLBACK_URL!,
  },
  kakao: {
    javascriptKey: process.env.REACT_APP_KAKAO_LOGIN_JAVASCRIPT_KEY!,
    redirectUri: process.env.REACT_APP_KAKAO_LOGIN_REDIRECT_URI!,
  },
};
