import { onMessage } from 'firebase/messaging';
import { messaging } from '@/lib/auth/firebase-auth';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { logoutModalState } from '@/atoms/logoutModalState';
import { authState } from '@/atoms/authState';
import { queryKey as NotesQueryKey } from '@/pages/Notes/hooks/useNotesQuery';
import { useQueryClient } from '@tanstack/react-query';
import { useLocation, useSearchParams } from 'react-router-dom';

export default function useOnMessage() {
  const setLogoutModalOpen = useSetRecoilState(logoutModalState);
  const [authInfo] = useRecoilState(authState);
  const { pathname } = useLocation();

  const queryClient = useQueryClient();

  const [searchParams] = useSearchParams();
  const type = searchParams.get('type');
  const sortKey = searchParams.get('sort_key');

  const { isLogged, email } = authInfo;

  const isIOS = /iPhone|iPad|iPod/i.test(window.navigator.userAgent);

  !isIOS &&
    onMessage(messaging!, async (payload) => {
      if (payload?.data?.type === 'reset_password') {
        const user = JSON.parse(payload?.data?.user);
        if (isLogged && email === user.email) {
          setLogoutModalOpen(true);
        }
      }

      if (payload.notification) {
        await new Notification(payload.notification.title as string, {
          body: payload.notification?.body,
          icon: '../images/touch/chrome-touch-icon-192x192.png',
          vibrate: [200, 100, 200, 100, 200, 100, 200],
          tag: 'vibration-sample',
        });
      }

      await queryClient.invalidateQueries(['notification-histories']);
      await queryClient.invalidateQueries(['notification-history-count']);

      if (payload.data && payload.data.type === 'note_info') {
        const updatedThread = payload.data && JSON.parse(payload.data.note as any);

        if (pathname === '/notes') {
          const previousThread = queryClient.getQueryData([...NotesQueryKey, type, sortKey]);

          await queryClient.setQueryData([...NotesQueryKey, type, sortKey], (old: any) => {
            const pages = old.pages.reduce((acc: any, cur: any) => {
              const rows = cur.rows.map((curPage: any) => {
                if (curPage.id === updatedThread.id) {
                  return {
                    ...curPage,
                    ...updatedThread,
                    recordingSttStatus: updatedThread.recording_stt_status,
                  };
                }

                return curPage;
              });

              return [...acc, { ...cur, rows }];
            }, []);

            return { ...old, pages };
          });

          return { previousThread };
        }

        if (pathname === `/notes/${updatedThread.id}`) {
          await queryClient.invalidateQueries(['note']);
        }

        if (pathname === '/home') {
          await queryClient.invalidateQueries(['recent-notes']);
        }
      }
    });
}
