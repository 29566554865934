import { useRecoilState } from 'recoil';
import { organizationState } from '@/atoms/organizationState';

export default function useFunctionGroupCheck(name: string) {
  const [organizationInfo] = useRecoilState(organizationState);
  const { functionGroup, isManage } = organizationInfo!;

  if (isManage || !functionGroup) {
    return false;
  }

  const { details } = functionGroup!;

  return details[name];
}
