import apiClient from '@/lib/api/apiClient';
import { queryKey } from './useNotificationsSettingQuery';
import { useMutation, useQueryClient } from '@tanstack/react-query';

const notificationSetting = async (settings: {
  system: boolean;
  notice: boolean;
  marketing: boolean;
}) => {
  try {
    const { data } = await apiClient.patch(
      `/notification-settings`,
      {
        settings,
      },
      {
        headers: {
          'x-platform': 'web',
        },
      },
    );
    return data;
  } catch (e) {
    throw e;
  }
};

export default function useNotificationsSetting() {
  const queryClient = useQueryClient();

  return useMutation(queryKey, notificationSetting, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey);
    },
  });
}
