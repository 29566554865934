import React from 'react';
import styled from '@emotion/styled';
import LiveImage from '@/assets/images/inducePayment/live.svg';
import ContentsImage from '@/assets/images/inducePayment/contetns.svg';
import FileImage from '@/assets/images/inducePayment/file.svg';

interface InducePaymentFooterProps {
  onSubscribe: (productUid: string) => void;
}

function InducePaymentFooter({ onSubscribe }: InducePaymentFooterProps) {
  const handelMonthlySubscribe = () => {
    onSubscribe('e329caf6-0113-11ef-927f-0af240c9f508');
  };
  const handelAnnualSubscribe = () => {
    onSubscribe('e329cacd-0113-11ef-927f-0af240c9f508');
  };

  return (
    <StyledInducePaymentFooter>
      <Description>
        <DescriptionItem>
          <img src={LiveImage} alt="live" />
          <DescriptionItemText>높은 정확도의 실시간 자막</DescriptionItemText>
        </DescriptionItem>
        <DescriptionDivider />
        <DescriptionItem>
          <img src={ContentsImage} alt="contents" />
          <DescriptionItemText>콘텐츠 자막</DescriptionItemText>
        </DescriptionItem>
        <DescriptionDivider />
        <DescriptionItem>
          <img src={FileImage} alt="file" />
          <DescriptionItemFileText>파일 음성 자막</DescriptionItemFileText>
        </DescriptionItem>
      </Description>
      <PaymentButton onClick={handelMonthlySubscribe}>
        <TextSection>
          <MonthTag>월간</MonthTag>
          <PaymentButtonText>플랜 구독</PaymentButtonText>
          <PromotionTag>25% 할인가</PromotionTag>
        </TextSection>
        <PriceSection>
          <Price>14,900</Price>
          <PriceUnit>원</PriceUnit>
        </PriceSection>
      </PaymentButton>
      <PaymentButton onClick={handelAnnualSubscribe}>
        <TextSection>
          <YearTag>연간</YearTag>
          <PaymentButtonText>플랜 구독</PaymentButtonText>
          <PromotionTag>38% 할인가</PromotionTag>
        </TextSection>
        <PriceSection>
          <Price>149,000</Price>
          <PriceUnit>원</PriceUnit>
        </PriceSection>
      </PaymentButton>
    </StyledInducePaymentFooter>
  );
}

export default InducePaymentFooter;

const StyledInducePaymentFooter = styled.div`
  display: flex;
  flex-direction: column;

  padding: 30px;
`;

const PaymentButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 60px;

  padding: 0 16px;
  margin-top: 20px;

  border-radius: 10px;
  background: linear-gradient(89deg, #2b3bcb 0%, #3462ff 100%);

  cursor: pointer;
`;

const TextSection = styled.div`
  display: flex;
  align-items: center;
`;

const PriceSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const MonthTag = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 10px;
  margin-right: 6px;

  border-radius: 16px;
  border: 1px solid ${(props) => props.theme.color.white.default};

  color: ${(props) => props.theme.color.white.default};
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
`;

const YearTag = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 10px;
  margin-right: 6px;

  border-radius: 16px;
  border: 1px solid ${(props) => props.theme.color.white.default};
  background-color: ${(props) => props.theme.color.white.default};

  color: #2b3bcb;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
`;

const PaymentButtonText = styled.span`
  color: ${(props) => props.theme.color.white.default};
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;

  margin-right: 17px;
`;

const PromotionTag = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 14px;

  border-radius: 16px;
  border: 1px solid #0ff;

  color: #0ff;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`;

const Price = styled.span`
  font-family: 'Pretendard', sans-serif !important;

  color: ${(props) => props.theme.color.white.default};
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  margin-right: 4px;
`;

const PriceUnit = styled.span`
  color: ${(props) => props.theme.color.white.default};
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

const Description = styled.span`
  width: 400px;
  height: 150px;

  padding: 20px 25px;
  margin-bottom: 10px;

  display: flex;
  justify-content: space-between;

  background-color: ${(props) => props.theme.color.white.default};
`;

const DescriptionItem = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  width: 95px;

  gap: 15px;
`;

const DescriptionDivider = styled.div`
  width: 1px;
  height: 110px;

  background: ${(props) => props.theme.color.gray.gray200};
`;

const DescriptionItemText = styled.span`
  width: 85px;
  height: 40px;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;

  text-align: center;
`;

const DescriptionItemFileText = styled.span`
  width: 90px;
  height: 40px;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;

  text-align: center;
`;
