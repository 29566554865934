import { useQuery } from '@tanstack/react-query';
import apiClient from '@/lib/api/apiClient';

export interface IUser {
  uid: string;
  displayName: string;
  email: string;
  mobile: string;
  accounts: any[];
}
export const queryKey = ['user'];

export const useUserQuery = () => {
  const { data, isLoading, error } = useQuery(
    [...queryKey],
    async () => {
      return (await apiClient.get(`/user`)) as { data: IUser };
    },
    {
      retry: false,
      cacheTime: 0,
    },
  );
  return { data: data ? data.data : null, isLoading, error };
};
