import { useMutation, useQueryClient } from '@tanstack/react-query';
import apiClient from '@/lib/api/apiClient';
import { queryKey } from '@/pages/Device/hooks/useDevicesQuery';
import { messageInfo } from '@/components/popup/message';

export default function useDevicesDeleteMutation() {
  const queryClient = useQueryClient();

  const deleteDevice = async (deviceId: number) => {
    const { data } = await apiClient.delete(`/device-ssaids/${deviceId}`);
    return data;
  };
  return useMutation(deleteDevice, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(queryKey);
      await messageInfo({
        content: '기기가 헤제 되었습니다.',
        noIcon: true,
      });
    },
  });
}
