import React, { Suspense, useEffect, useState } from 'react';
import { Route, Routes, useMatch, useNavigate, useSearchParams } from 'react-router-dom';
import useAuthStateChanged from '@/hooks/useAuthStateChanged';
import useNetworkMonitoring from '@/hooks/useNetworkMonitoring';
import useRouteChangeTracker from '@/hooks/useRouteChangeTracker';
import NetworkStatusAlert from '@/components/popup/NetworkStatusAlert';
import Loading from '@/components/loading/Loading';
import AppLayout from '@/components/app/AppLayout';
import Account from '@/pages/Account';
import UsageState from '@/pages/UsageState';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { isMobileState } from '@/atoms/isMobileState';
import { hideNavState } from '@/atoms/navState';
import { messageInfo } from '@/components/popup/message';
import { deviceTokenState } from '@/atoms/deviceTokenState';
import useLogoutMutation from '@/hooks/useLogoutMutation';
import { ConfigProvider } from 'antd';
import koKR from 'antd/es/locale/ko_KR';
import theme from '@/assets/styles/theme';
import UserInviteCode from '@/pages/Account/UserInviteCode';
import { lazyWithRetry } from '@/lib/util/lazy';
import Organizations from '@/pages/Organizations';
import { PiPProvider } from '@/components/pip/PiPProvider';

const SignIn = lazyWithRetry(() => import('@/pages/SignIn'));
const RequestPasswordReset = lazyWithRetry(() => import('@/pages/RequestPasswordReset'));
const SignUp = lazyWithRetry(() => import('@/pages/SignUp/SignUp'));
const Terms = lazyWithRetry(() => import('@/pages/Terms'));
const SingUpSuccess = lazyWithRetry(() => import('@/pages/SingUpSuccess'));
const Home = lazyWithRetry(() => import('@/pages/Home'));
const Notes = lazyWithRetry(() => import('@/pages/Notes'));
const NoteDetail = lazyWithRetry(() => import('@/pages/NoteDetail'));
const Trash = lazyWithRetry(() => import('@/pages/Trash'));
const TermsAndConditions = lazyWithRetry(() => import('@/pages/TermsAndConditions'));
const TermsAndConditionsDetail = lazyWithRetry(() => import('@/pages/TermsAndConditionDetail'));
const NotFound = lazyWithRetry(() => import('@/pages/Notfound'));
const UnsupportedBrowser = lazyWithRetry(() => import('@/pages/UnsupportedBrowser'));
const EmailActionResult = lazyWithRetry(() => import('@/pages/EmailActionResult'));
const PasswordReset = lazyWithRetry(() => import('@/pages/PasswordReset'));
const Upload = lazyWithRetry(() => import('@/pages/Upload'));
const Notice = lazyWithRetry(() => import('@/pages/Notice'));
const UserGuide = lazyWithRetry(() => import('@/pages/UserGuide'));
const Devices = lazyWithRetry(() => import('@/pages/Device/Devices'));
const InviteCodes = lazyWithRetry(
  () => import('@/pages/OrganizationsInviteCodes/OrganizationsInviteCodes'),
);
const OrganizationUsers = lazyWithRetry(() => import('@/pages/OrganizationUsers'));
const Subscriptions = lazyWithRetry(() => import('@/pages/Subscriptions/Subscriptions'));
const PaymentSuccess = lazyWithRetry(
  () => import('@/pages/SubscriptionSuccess/PaymentMethodSuccess'),
);
const PaymentFail = lazyWithRetry(() => import('@/pages/SubscriptionFail/PaymentMethodFail'));
const SubscriptionSuccess = lazyWithRetry(
  () => import('@/pages/SubscriptionSuccess/SubscriptionSuccess'),
);
const SubscriptionFail = lazyWithRetry(() => import('@/pages/SubscriptionFail/SubscriptionFail'));
const Unsubscription = lazyWithRetry(() => import('@/pages/Subscriptions/Unsubscription'));
const OrganizationStatistics = lazyWithRetry(() => import('@/pages/OrganizationStatistics'));
const SovoroSpeechRecognition = lazyWithRetry(() => import('@/pages/Recognition'));
const SocialAccountCallback = lazyWithRetry(() => import('@/pages/SocialAccountCallback'));

function App() {
  useAuthStateChanged();
  useRouteChangeTracker();
  const onLine = useNetworkMonitoring();

  const navigate = useNavigate();
  const [searchParam] = useSearchParams();

  const matchSignUpStep = useMatch('/sign-up/step/*');
  const code = searchParam.get('code');

  const { mutate } = useLogoutMutation();

  const [isAndroid, setIsAndroid] = useState(false);
  const [deviceToken] = useRecoilState(deviceTokenState);

  const setIsMobile = useSetRecoilState(isMobileState);

  const setHideNav = useSetRecoilState(hideNavState);

  const unloadEvent = (e: Event) => {
    e.preventDefault();
    mutate(deviceToken);
  };

  useEffect(() => {
    if (matchSignUpStep) {
      navigate(`sign-up${code ? `?code=${code}` : ''}`);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('unload', unloadEvent);
    return () => {
      window.removeEventListener('unload', unloadEvent);
    };
  }, [deviceToken, mutate, unloadEvent]);

  useEffect(() => {
    const handleClick = () => {
      if (!onLine) {
        messageInfo({
          content: '오프라인 상태에서는 이용하실 수 없습니다.',
          noIcon: true,
        });
      }
    };

    window.addEventListener('click', handleClick);

    return () => window.removeEventListener('click', handleClick);
  }, [onLine]);

  useEffect(() => {
    const handleClick = () => {
      if (!onLine) {
        messageInfo({
          content: '오프라인 상태에서는 이용하실 수 없습니다.',
          noIcon: true,
        });
      }
    };

    window.addEventListener('click', handleClick);

    return () => window.removeEventListener('click', handleClick);
  }, [onLine]);

  useEffect(() => {
    const isMobileAgent = /iPhone|iPad|iPod|Android/i.test(window.navigator.userAgent);
    setIsMobile(isMobileAgent);

    setIsAndroid(/Android/i.test(window.navigator.userAgent));

    if (isMobileAgent) {
      setHideNav(true);
    }
  }, [setHideNav, setIsAndroid, setIsMobile]);

  return (
    <ConfigProvider
      locale={koKR}
      theme={{
        token: {
          colorTextBase: theme.color.gray.gray900,
          colorTextSecondary: theme.color.gray.gray400,
        },
        components: {
          Dropdown: {
            controlItemBgActive: '#ffff',
            controlItemBgActiveHover: '#ffff',
          },
        },
      }}
    >
      {!onLine && <NetworkStatusAlert />}
      <Suspense fallback={<Loading />}>
        <Routes>
          {/* public */}
          <Route path="/sign-in" element={<SignIn />} />
          <Route path="/request-password-reset" element={<RequestPasswordReset />} />
          <Route path="/sign-up" element={<SignUp />} />

          <Route path="/organizations" element={<Organizations />} />
          <Route path="/sign-up/success" element={<SingUpSuccess />} />
          <Route path="/terms/:name" element={<Terms />}>
            <Route index element={<Terms />} />
            <Route path=":notionId" element={<Terms />} />
          </Route>
          <Route path="/callback/:provider" element={<SocialAccountCallback />} />

          {/* private */}
          <Route path="/" element={<AppLayout />}>
            <Route path="subscriptions">
              <Route index element={<Subscriptions />} />
              <Route path="success" element={<SubscriptionSuccess />} />
              <Route path="fail" element={<SubscriptionFail />} />
            </Route>
            <Route path="unsubscription" element={<Unsubscription />} />
            <Route path="payments/success" element={<PaymentSuccess />} />
            <Route path="payments/fail" element={<PaymentFail />} />

            <Route
              path="/recognition"
              element={
                <PiPProvider>
                  <SovoroSpeechRecognition />
                </PiPProvider>
              }
            />
            <Route path="home" element={<Home />} />
            <Route path="notes">
              <Route index element={<Notes />} />
              <Route path=":noteId" element={<NoteDetail />} />
              <Route path=":noteId/:mode" element={<NoteDetail />} />
            </Route>
            <Route path="usage-state" element={<UsageState />} />
            <Route path="trash-notes">
              <Route index element={<Trash />} />
              <Route path=":noteId" element={<NoteDetail />} />
            </Route>
            <Route path="terms-and-conditions">
              <Route index element={<TermsAndConditions />} />
              <Route path=":name" element={<TermsAndConditionsDetail />} />
              <Route path=":name/:notionId" element={<TermsAndConditionsDetail />} />
            </Route>

            <Route path="account">
              <Route index element={<Account />} />
              <Route path="invite-codes" element={<UserInviteCode />} />
            </Route>
            <Route path="upload" element={<Upload />} />
            <Route path="notice">
              <Route index element={<Notice />} />
              <Route path=":notionId" element={<Notice />} />
            </Route>
            <Route path="user-guide">
              <Route index element={<UserGuide />} />
              <Route path=":notionId" element={<UserGuide />} />
            </Route>
            <Route path="organizations">
              <Route path="users" element={<OrganizationUsers />} />
              <Route path="statistics" element={<OrganizationStatistics />} />
              <Route path="invite-codes" element={<InviteCodes />} />
            </Route>
          </Route>

          <Route path="action-result" element={<EmailActionResult />} />
          <Route path="password-reset" element={<PasswordReset />} />

          {/* 지원하지 않는 브라우저 접속시 */}
          <Route path="unsupported-browser" element={<UnsupportedBrowser />} />

          {/* 상단에 위치하는 라우트들의 규칙을 모두 확인, 일치하는 라우트가 없는경우 처리 */}
          <Route path="not-found" element={<NotFound />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
      {/* TODO 모바일에서 웹으로 접근 */}
      {/*{isAndroid && appModalVisible && (*/}
      {/*  <Modal*/}
      {/*    title="소보로 앱으로 접속하시겠어요?"*/}
      {/*    okText="앱으로 접속"*/}
      {/*    cancelText="아니오"*/}
      {/*    onOk={handleAppModal}*/}
      {/*    onCancel={handleAppModal}*/}
      {/*  >*/}
      {/*    <p>소보로 모바일 앱에서도 사용하실 수 있어요.</p>*/}
      {/*  </Modal>*/}
      {/*)}*/}
    </ConfigProvider>
  );
}

export default App;
