import apiClient from '@/lib/api/apiClient';
import { SortDirection } from '@/hooks/useNotesInfiniteQuery';
import { useInfiniteQuery } from '@tanstack/react-query';

export interface INotificationHistoryData {
  id: number;
  notification: {
    id: number;
    type: string;
    title: string;
    body: string;
    link: string;
  };
  isRead: boolean;
  createdAt: string;
  updatedAt: string;
}

interface IDefaultFilter {
  page: number;
  perPage: number;
  sortKey?: string;
  sortDirection: SortDirection;
}

export default function useNotificationInfiniteQuery(
  queryKey: string[],
  select: any,
  defaultFilter: IDefaultFilter,
) {
  async function fetchServerPage(lastIndex: number = 0): Promise<{
    rows: any[];
    nextOffset: number | undefined;
    total: number;
  }> {
    const filter = {
      ...defaultFilter,
      ...(lastIndex !== 0 && { lastIndex }),
    };

    const {
      data: rows,
      meta: { restCount, total },
    } = (await apiClient.get('/notification-histories', filter)) as {
      data: INotificationHistoryData[];
      meta: {
        restCount: number;
        total: number;
      };
    };

    return { rows, total, nextOffset: restCount > 0 ? rows[rows.length - 1].id : undefined };
  }

  const { status, data, error, isFetching, isFetchingNextPage, fetchNextPage, hasNextPage } =
    useInfiniteQuery(
      [...queryKey],
      async (ctx) => {
        return await fetchServerPage(ctx.pageParam);
      },
      {
        getNextPageParam: (_lastGroup, groups) => {
          return _lastGroup.nextOffset;
        },
        select,
        refetchOnWindowFocus: true,
      },
    );

  return { status, data, error, isFetching, isFetchingNextPage, fetchNextPage, hasNextPage };
}
