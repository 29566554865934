import React from 'react';
import AntdSelect, { InternalSelectProps } from 'antd/es/select';
import styled from '@emotion/styled';
import { ReactComponent as IconUpArrow } from '@/assets/images/button/icon-up-arrow.svg';
import { ReactComponent as IconDownArrow } from '@/assets/images/button/icon-down-arrow.svg';
import { css } from '@emotion/react';
import theme from '@/assets/styles/theme';
import { media } from '@/assets/styles/GlobalStyles';

export interface SelectProps extends InternalSelectProps {
  dropdownState: boolean;
  value?: string | number | null;
  onChange?: (value: any) => void;
}

function SovoroSelect(props: SelectProps) {
  const {
    dropdownState,
    value,
    optionLabelProp,
    onDropdownVisibleChange,
    onChange,
    bordered,
    children,
    options,
  } = props;

  return (
    <CustomSelect
      onDropdownVisibleChange={onDropdownVisibleChange}
      css={css`
        margin: 16px 16px 0;

        ${media.mobile} {
          margin: 10px 10px 0;
        }

        span {
          color: ${theme.color.gray.gray500};
          padding: 0 !important;
          background-image: none !important;
        }
      `}
      suffixIcon={dropdownState ? <IconUpArrow /> : <IconDownArrow />}
      defaultValue={value}
      value={value}
      options={options}
      onChange={onChange}
      optionLabelProp={optionLabelProp}
      bordered={bordered ? bordered : false}
    >
      {children}
    </CustomSelect>
  );
}

export default SovoroSelect;

const CustomSelect = styled(AntdSelect)``;
