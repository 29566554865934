import { useMutation } from '@tanstack/react-query';
import { useSetRecoilState } from 'recoil';
import { inviteCodeErrorState } from '@/pages/Account/atoms/inviteCodeErrorState';
import apiClient from '@/lib/api/apiClient';
import { AxiosResponse } from 'axios';
import { inviteCodeJoinSuccessState } from '@/pages/Account/atoms/invitecodeJoinSuccessState';

export default function useUserInviteCodeMutation() {
  const setInviteCodeError = useSetRecoilState(inviteCodeErrorState);
  const setInviteCodeJoinSuccess = useSetRecoilState(inviteCodeJoinSuccessState);

  const joinWithInviteCode = async (inviteCode: string) => {
    try {
      const { data } = await apiClient.post('/organization-users', { inviteCode: inviteCode });
      return data;
    } catch (e) {
      throw e;
    }
  };

  return useMutation(joinWithInviteCode, {
    onSuccess: async () => {
      await setInviteCodeJoinSuccess(true);
    },
    onError: async (error: AxiosResponse) => {
      await setInviteCodeError(error.data.code);
    },
  });
}
